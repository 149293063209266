import React from "react";
import { connect } from "react-redux";
import { setError } from "../../redux/slice";
import { doLogout } from "../auth/stores/slice";

export default (Component) => {
  class Container extends React.Component {
    render() {
      return <Component {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    const { error } = state.common;
    return {
      error: error,
    };
  }
  return connect(mapStateToProps, { setError, doLogout })(Container);
};
