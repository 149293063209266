import { createAsyncThunk } from "@reduxjs/toolkit";
import { setGlobalLoading } from "../../../redux/slice";
import * as api from "../api";

export const doSignIn = createAsyncThunk(
  "auth/doSignIn",
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.signIn(params);
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response.data;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  }
);

export const doVerifyOtp = createAsyncThunk(
  "auth/doVerifyOtp",
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.verifyOtp(params);
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response.data;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  }
);

export const doGetAuthenticatorQRCode = createAsyncThunk(
  "auth/doGetAuthenticatorQRCode",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.getAuthQRCode();
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response.data;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  }
);

export const doGetUserBalance = createAsyncThunk(
  "auth/doGetUserBalance",
  async (_, thunkAPI) => {
    // console.log("asdnjkaskd")
    try {
      console.log("tryingBalance")
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.getUserBalance();
      console.log(response)
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response.data;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  }
);

export const doChangePassword = createAsyncThunk(
  "auth/doChangePassword",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.changePassword();
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response.data;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  }
);
