import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleFilterParams } from "../../../utils/global";
import * as api from "../api";

export const doGetAllBets = createAsyncThunk(
  "companies/doGetAllBets",
  async (params, thunkAPI) => {
    const newParams = handleFilterParams(params);
    try {
      const { response, error } = await api.doGetAllBets(newParams);
      return error ? thunkAPI.rejectWithValue(error) : response.data;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  }
);

