import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { MainLayout } from "../../../Components/Layout";
import { doGetUserBalance } from '../../userBalance/stores/thunk';

const PrivateRoute = ({auth, doGetUserBalance, ...props}) => {
  const { location } = props;

  useEffect(() => {
    doGetUserBalance({});
  }, []);

  return (
    auth && auth.authenticated && auth.verified ? (
      <MainLayout auth={auth} {...props}>
        <Outlet context={{...props}} />
      </MainLayout>
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  );
}

export default connect(
  (state) => ({
    common: state.common,
    auth: state.auth,
    userBalance: state.userBalance.userBalance,
  }),
  {
    doGetUserBalance,
  }
)(PrivateRoute);
