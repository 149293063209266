import request from "../../utils/request";

export function signIn(params) {
  return request("/login", {
    method: "POST",
    data: params,
  });
}

export function verifyOtp(params) {
  return request("/login/verify", {
    method: "POST",
    data: params,
  });
}

export function getAuthQRCode() {
  return request("/admin/qr-code", {
    method: "GET",
  });
}


export function doRefreshToken({refreshToken}) {
  return request("/login/refresh-token", {
    method: "POST",
    data: { refreshToken },
  });
}

export function getUserBalance() {
  return request("/account/info", {
    method: "GET",
  });
}


export function changePassword(params) {
  return request("/admin/password", {
    method: "POST",
    data: params,
  });
}