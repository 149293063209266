import React, { useEffect } from 'react';
import PrimaryButton from '../Components/Buttons/PrimaryButton';
import { ScanQRCodeContainer } from '../modules/auth/containers';
import { useNavigate } from 'react-router-dom';
import './ScanAuthenticatorCode.scss';
import { notification } from 'antd';

const ScanAuthenticatorCodePage = ({ auth, doGetAuthenticatorQRCode, doLogout}) => {
  const navigate = useNavigate();

  useEffect(() => {
    doGetAuthenticatorQRCode();
  }, []);

  const onDoneClick = () => {
    doLogout({});
    navigate('/login');
    notification.info({
      message: `You will have to login again.`,
      placement: "top"
    });
  }

  return (
    <div className="scan-authenticator-code-container">
      <div className="content-page">
        <div className='qrcode-container'>
          {
            auth && auth.qrCode && (
              <div dangerouslySetInnerHTML={{__html: auth.qrCode}}></div>
            )
          }
          <div className='action-page'>
            <PrimaryButton label={'Done'} onClick={onDoneClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanQRCodeContainer(ScanAuthenticatorCodePage);
