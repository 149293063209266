import { Button, Collapse, Form, Select } from 'antd';
import React, { useEffect } from 'react';
import { BOOKIE_LIST } from '../../../constants';

const { Panel } = Collapse;

const BetsFilter = ({
  query,
  leagues,
  isLeagueFetching,
  handleFilter,
  handleResetFilter,
}) => {
  const [form] = Form.useForm();

  const onLeagueChange = (value) => {
    const filter = query.filter || {};
    filter['league'] = value;
    handleFilter({ ...query, filter: filter }, true);
  }

  const onBookieChange = (value) => {
    const filter = query.filter || {};
    filter['source'] = value;
    handleFilter({ ...query, filter: filter }, true);
  }

  const resetParams = () => {
    form.resetFields();
    handleResetFilter();
  }

  useEffect(() => {
    if (query) {
      const fields = query['filter'];
      if (fields) form.setFieldsValue(fields);
    }
  }, [query]);

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header="Search Options" key="1">
        <Form form={form} colon={false} initialValues={{league: null, source: null}}>
          <Form.Item label="League" name="league" id="betLeagueParam">
            <Select
              loading={isLeagueFetching}
              showSearch
              size="medium"
              onChange={onLeagueChange}
            >
              <Select.Option value={null}>Any</Select.Option>
              {leagues?.map((league) => (
                <Select.Option key={league.id} value={league.id}>
                  {league.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="bookieParam" label="Bookie" name="source">
            <Select
              size="medium"
              onChange={onBookieChange}
            >
              <Select.Option value={null}>Any</Select.Option>
              {BOOKIE_LIST?.map((bookie) => (
                <Select.Option key={bookie.value} value={bookie.value}>
                  {bookie.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* 
          <Form.Item label="Bet Creation Date" name="dateRange">
            <RangePicker
              format={'ddd DD/MM/YYYY'}
              onChange={(newValue) => {
                console.log(newValue);
                searchParams.set('filter[createdAtFrom]', newValue[0].format('DD/MM/YYYY'));
                searchParams.set('filter[createdAtTo]', newValue[1].format('DD/MM/YYYY'));
                setSearchParams(searchParams);
                console.log(currentParams['filter[createdAtFrom]']);
              }}
            />
          </Form.Item>
          {quickSelectionTable} */}
          <Button variant="contained" onClick={resetParams}>
            Reset
          </Button>
        </Form>
      </Panel>
    </Collapse>
  )
}

export default BetsFilter;