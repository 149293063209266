import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import * as qs from 'qs';

export default function (Component, ownProps) {
  const Page = () => {
    const props = useOutletContext();
    const match = useParams();
    const navigate = useNavigate();
    
    const {
      location: { search, pathname },
    } = props;
    const params = match.params;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    const pushTo = (pushProps) => {
      navigate({
        pathname: pushProps.path || pathname,
        search: qs.stringify(pushProps.query, { encode: false, skipNulls: true }),
        state: pushProps.state,
      });
    };
    const editable = params?.id && match.path.indexOf("edit") > -1;
    return (
      <Component
        {...props}
        id={params?.id || ownProps?.id || undefined}
        editable={editable}
        query={query}
        params={params}
        pushTo={pushTo}
      />
    );
  };
  return Page;
}
