export const calculateEdge = (bet) => {
  switch (bet.selected) {
    case 'HOME':
      return (bet.odd.rateOver - bet.homeTrueOdd) / bet.homeTrueOdd;
    case 'AWAY':
      return (bet.odd.rateUnder - bet.awayTrueOdd) / bet.awayTrueOdd;
    case 'DRAW':
      return (bet.odd.rateEqual - bet.drawTrueOdd) / bet.drawTrueOdd;
  }
};

export const givenOdds = (bet) => {
  switch (bet.selected) {
    case 'HOME':
      return bet.odd.rateOver;
    case 'AWAY':
      return bet.odd.rateUnder;
    case 'DRAW':
      return bet.odd.rateEqual;
  }
};

export const calculateWinnings = (bet) => {
  switch (bet.selected) {
    case 'HOME':
      return bet.odd.rateOver * bet.amount - bet.amount;
    case 'AWAY':
      return bet.odd.rateUnder * bet.amount - bet.amount;
    case 'DRAW':
      return bet.odd.rateEqual * bet.amount - bet.amount;
  }
};

export const totalAmountRisk = (validBets) => {
  return validBets
    .filter((item) => item.winners)
    .reduce((total, item) => {
      return total + item.amount;
    }, 0);
};

export const expectedWinnings = (bet) => {
  return (bet.amount * calculateEdge(bet));
};

export const sumOfExpectedWinnings = (validBets) => {
  return validBets
    .filter((item) => item.winners)
    .reduce((total, item) => {
      return total + expectedWinnings(item);
    }, 0);
};

export const expectedROI = (validBets) => {
  return sumOfExpectedWinnings(validBets) / totalAmountRisk(validBets);
};

export const actualWinnings = (bet) => {
  if (bet.selected === bet.winners) {
    return bet.amount * givenOdds(bet) - bet.amount;
  }
  if (bet.selected != bet.winners) {
    return 0 - bet.amount;
  }
};

export const sumOfActualWinnings = (validBets) => {
  return validBets
    .filter((item) => item.winners)
    .reduce((total, item) => {
      return total + actualWinnings(item);
    }, 0);
};

export const actualROI = (validBets) => {
    return sumOfActualWinnings(validBets) / totalAmountRisk(validBets);
  };

export const totalAmountPending = (validBets) => {
  return validBets
    .filter((item) => !item.winners)
    .reduce((total, item) => {
      return total + item.amount;
    }, 0);
};
