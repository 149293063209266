import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { headerConfig } from '../utils/header';
import { Collapse, Table, Tooltip } from 'antd';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const { Panel } = Collapse;

const DetailsPage = () => {
  const headers = headerConfig();
  let { id } = useParams();
  const [betDetailsData, setBetDetailsData] = useState([]);
  const [matchDetailsData, setMatchDetailsData] = useState([{}]);
  useEffect(() => {
    const configBets = {
      method: 'get',
      url: `${BACKEND_URL}/bets/?filter[matchId]=${id}`,
      headers: headers,
      timeout: 60000,
    };
    const configMatch = {
      method: 'get',
      url: `${BACKEND_URL}/matches/${id}`,
      headers: headers,
      timeout: 60000,
    };

    const getDataBets = async () => {
      try {
        console.log('get data match');
        const response = await axios(configBets);
        console.log(configBets.url)
        console.log(response);
        setBetDetailsData(response.data.data);
      } catch {}
    };
    const getDataMatch = async () => {
      try {
        const response = await axios(configMatch);
        console.log(response);
        setMatchDetailsData(response.data);
      } catch {}
    };
    getDataBets();
    getDataMatch();
  }, []);

  const sortedByBookie = betDetailsData.sort(function (a, b) {
    const textA = a.source.toUpperCase();
    const textB = b.source.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const uniqueBookies2 = betDetailsData.map((item) => item.source).filter((v, i, a) => a.indexOf(v) === i);
  console.log(uniqueBookies2);

  const uniqueBookies = sortedByBookie.filter((value, index, self) => {
    return self.findIndex((v) => v.source === value.source) === index;
  });

  const individualBookieBets = (source) => {
    return betDetailsData?.filter((item) => item.source === source);
  };
  console.log(individualBookieBets('BETFAIR'));

  const mappedIndividualBetsByBookie = (source) => individualBookieBets(source);
  console.log(mappedIndividualBetsByBookie('BETFAIR'));

  const calculateWinnings = (bet) => {
    switch (bet.selected) {
      case 'HOME':
        return (bet.odd.rateOver - 1) * bet.amount;
      case 'AWAY':
        return (bet.odd.rateUnder - 1) * bet.amount;
      case 'DRAW':
        return (bet.odd.rateEqual - 1) * bet.amount;
    }
  };

  const totalWinningsArray = betDetailsData.map((item) => {
    if (item.selected != item.winners) {
      return -item.amount;
    }
    return calculateWinnings(item);
  });
  const sumOfWinnings = totalWinningsArray.reduce((accumulated, currentValue) => accumulated + currentValue, 0);

  console.log(sumOfWinnings);

  const bookieDetailsColumns = [
    {
      title: "Number of Bets",
      dataIndex: 'numberOfBets',
      key: 'numberOfBets',
      width: 100,
      align: 'center',
    },
    {
      title: "True Odds",
      width: 100,
      children: [
        {
          title: 'Home',
          dataIndex: 'homeTrueOdd',
          key: 'homeTrueOdd',
          width: 70,
  
          align: 'center',
        },
        {
          title: 'Draw',
          dataIndex: 'drawTrueOdd',
          key: 'drawTrueOdd',
          width: 70,
  
          align: 'center',
        },
        {
          title: 'Away',
          dataIndex: 'awayTrueOdd',
          key: 'awayTrueOdd',
          width: 70,
  
          align: 'center',
        },
      ],
    },
    {
      title: "Winner",
      dataIndex: 'winners',
      key: 'winners',
      width: 200,
      align: 'center',
      render: (value) => (value ? value : 'Match pending...'),
    },
    {
      title: "Total Bet Size",
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 100,
      align: 'center',
      render: (value) => `$${value}`,
    },
    {
      title: "Total Net",
      dataIndex: 'totalNet',
      key: 'totalNet',
      width: 100,
      align: 'center',
      render: (value) => (value >= 0 ? `$${value.toFixed(2)}` : `-${Math.abs(value).toFixed(2)}`),
    },
  ];

  const bookieDetailsParsed = (item) => [
    {
      id: '0',
      numberOfBets: individualBookieBets(item.source).length,
      homeTrueOdd: item.homeTrueOdd,
      drawTrueOdd: item.drawTrueOdd,
      awayTrueOdd: item.awayTrueOdd,
      winners: item.winners,
      totalAmount: individualBookieBets(item.source)
        .map((item) => item.amount)
        .reduce((accumulated, currentValue) => accumulated + currentValue, 0),
      totalNet: individualBookieBets(item.source)
        .map((item) => calculateWinnings(item))
        .reduce((accumulated, currentValue) => accumulated + currentValue, 0),
    },
  ];

  const betsDetailsColumns = [
    {
      title: "Bet Number.",
      dataIndex: 'number',
      key: 'number',
      width: 100,
      align: 'center',
      render: (number) => `Bet #${number}`,
    },
    {
      title: "True Odds",
      width: 100,
      children: [
        {
          title: 'Home',
          dataIndex: 'homeGivenOdds',
          key: 'homeGivenOdds',
          width: 70,
  
          align: 'center',
        },
        {
          title: 'Draw',
          dataIndex: 'drawGivenOdds',
          key: 'drawGivenOdds',
          width: 70,
  
          align: 'center',
        },
        {
          title: 'Away',
          dataIndex: 'awayGivenOdds',
          key: 'awayGivenOdds',
          width: 70,
  
          align: 'center',
        },
      ],
    },
    {
      title: "Bet Selection",
      dataIndex: 'betSelection',
      key: 'betSelection',
      width: 100,
      align: 'center',
    },
    {
      title: "Bet Win?",
      dataIndex: 'betWin',
      key: 'betWin',
      width: 100,
      align: 'center',
    },
    {
      title: "Bet Size",
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      align: 'center',
      render: (value) => `$${value}`,
    },
    {
      title: "Net",
      dataIndex: 'net',
      key: 'net',
      width: 100,
      align: 'center',
    },
  ];
  console.log(individualBookieBets('BETFAIR'));
  const betDetailsParsed = (item) =>
    individualBookieBets(item).map((item, index) => {
      return {
        id: '0',
        number: index + 1,
        homeGivenOdds: item.odd.rateOver,
        drawGivenOdds: item.odd.rateEqual,
        awayGivenOdds: item.odd.rateUnder,
        betSelection: item.selected,
        amount: item.amount,
        betWin: item.winners === item.selected ? 'Yes' : 'No',
        net: item.selected === item.winners ? `$${calculateWinnings(item).toFixed(2)}` : `-$${item.amount.toFixed(2)}`,
      };
    });

  console.log(betDetailsParsed('BETFAIR'));

  const bettingData = uniqueBookies.map((item, index) => {
    return (
      <Panel header={item.source} key={index}>
        <Table
          bordered
          pagination={false}
          columns={bookieDetailsColumns}
          dataSource={bookieDetailsParsed(item)}
          rowKey="source"
        />
        <Table
          bordered
          pagination={false}
          columns={betsDetailsColumns}
          dataSource={betDetailsParsed(item.source)}
          rowKey="number"
        />
        {/* <tbody>
          <tr>
            <th rowSpan="2">Bets</th>
            <th colSpan="3">Given Odds</th>
            <th rowSpan="2">Bet Size</th>
            <th rowSpan="2">Bet Win?</th>
            <th rowSpan="2">Net Profit</th>
          </tr>
          <tr>
            <th>Home</th>
            <th>Away</th>
            <th>Draw</th>
          </tr>
          {mappedBets(item.source)}
        </tbody>{' '} */}
      </Panel>
    );
  });

  const matchTableColumns = [
    {
      title: "League",
      dataIndex: 'league',
      key: 'league',
      width: 100,
      align: 'center',
    },
    {
      title: "Home",
      dataIndex: 'host',
      key: 'host',
      width: 100,
      align: 'center',
    },
    {
      title: "Away",
      dataIndex: 'guest',
      key: 'guest',
      width: 100,
      align: 'center',
    },
    {
      title: "Start Time",
      dataIndex: 'startTime',
      key: 'startTime',
      width: 100,
      align: 'center',
      render: (number) => moment.unix(number).format('ddd D MMM YY HH:mm:ss'),
    },
    {
      title: "Number of Bets",
      dataIndex: 'numberOfBets',
      key: 'numberOfBets',
      width: 100,
      align: 'center',
    },
    {
      title: "Total Bet Size",
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 100,
      align: 'center',
      render: (value) => `$${value}`,
    },
    {
      title: "Total Net",
      dataIndex: 'totalNet',
      key: 'totalNet',
      width: 100,
      align: 'center',
      render: (value) => (value >= 0 ? `$${value.toFixed(2)}` : `-${Math.abs(value).toFixed(2)}`),
    },
  ];

  const matchDataParsed = [
    {
      id: '0',
      league: matchDetailsData.league,
      host: matchDetailsData.host,
      guest: matchDetailsData.guest,
      startTime: matchDetailsData.startTime,
      numberOfBets: betDetailsData.length,
      totalAmount: betDetailsData
        .map((item) => item.amount)
        .reduce((accumulated, currentValue) => accumulated + currentValue, 0),
      totalNet: betDetailsData
        .map((item) => calculateWinnings(item))
        .reduce((accumulated, currentValue) => accumulated + currentValue, 0),
    },
  ];

  console.log(uniqueBookies);
  return (
    <section id="details">
      <Table bordered columns={matchTableColumns} dataSource={matchDataParsed} pagination={false} rowKey="id" />

      {betDetailsData.length === 0 ? <p>No bets recorded for this match.</p> : <Collapse>{bettingData}</Collapse>}
      {/* <table>
        <tbody>
          <tr>
            <td colSpan={6}>Total Winnings</td>
            <td>{sumOfWinnings >= 0 ? `$${sumOfWinnings}` : `-$${Math.abs(sumOfWinnings)}`}</td>
          </tr>
        </tbody>
      </table> */}
    </section>
  );
};

export default DetailsPage;
