import React, { Fragment } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  SignInPage,
  VerifyOtpPage,
  ScanAuthenticatorCodePage,
  E404Page,
  BetsPage,
  MatchesPage,
  DetailsPage,
  OddsPage,
  OddsDetailsPage,
  BetLogPage,
  LeaguesPage,
  BetsCalculationPage,
  LogoutPage,

} from '../Pages';

import AppError from './AppError';

import { PrivateRoute } from '../modules/auth/containers';
import { Details } from '@mui/icons-material';

const App = () => {
  const location = useLocation();
  return (
    <Fragment>
      <AppError />
      <Routes>
        <Route exact path="/login" element={<SignInPage />} />
        <Route exact path="/verify-otp" element={<VerifyOtpPage />} />
        <Route exact path="/scan-authenticator-code" element={<ScanAuthenticatorCodePage />} />
        <Route exact path="/logout" element={<LogoutPage />} />

        <Route element={<PrivateRoute location={{ ...location }} />}>
          <Route exact path="/" element={<BetsPage />} />
          {/* <Route exact path="/matches/" element={<MatchesPage />} /> */}
          <Route exact path="/details/:id" element={<DetailsPage />} />
          <Route exact path="/matches" element={<MatchesPage />} />
          <Route exact path="/matches/:id" element={<DetailsPage />} />
          <Route exact path="/odds" element={<OddsPage />} />
          <Route exact path="/odds/:id" element={<OddsDetailsPage />} />
          <Route exact path="/betlog" element={<BetLogPage />} />
          <Route exact path="/leagues" element={<LeaguesPage />} />
          <Route exact path="/betscalculation" element={<BetsCalculationPage />} />
        </Route>
        <Route path="*" element={<E404Page />} />
      </Routes>
    </Fragment>
  );
};
export default App;
