import React from "react";
import { connect } from "react-redux";

import {
  doGetAllBets,
} from "../stores/thunk";
import { selectAllBets } from "../stores/slice";
import { selectAllLeagues } from "../../league/stores/slice";
import { doGetAllLeagues } from "../../league/stores/thunk";

export default (Component) => {
  class Container extends React.Component {
    render() {
      return <Component {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    return {
      isFetching: state.bet.isFetching,
      metadata: state.bet.metadata,
      bets: selectAllBets(state),
      leagues: selectAllLeagues(state),
      isLeagueFetching: state.league.isFetching,
    };
  }

  return connect(mapStateToProps, {
    doGetAllBets,
    doGetAllLeagues,
  })(Container);
};
