import { Button } from "antd";
import React from "react";
import "./PrimaryButton.scss";

const PrimaryButton = ({
  label,
  icon,
  htmlType,
  loading,
  style,
  color,
  className,
  onClick,
  size,
}) => {
  return (
    <Button
      type="primary"
      className={`btn-primary ${color} ${className} ${size}`}
      htmlType={htmlType}
      loading={loading}
      style={style}
      onClick={onClick}
    >
      {label}
      {icon}
    </Button>
  );
};

export default PrimaryButton;
