import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { headerConfig } from '../utils/header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BetLogTable from '../Components/Betlog/BetlogTable';
import BetlogParameterInputs from '../Components/Betlog/BetlogParameterInputs';
import '../Components/Betlog/Betlog.css';
import { notification } from 'antd';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const BetLogPage = () => {
  const headers = headerConfig();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [betLogData, setBetLogData] = useState([]);
  const [csvRawData, setCsvRawData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const currentParams = Object.fromEntries([...searchParams]);

  const sessionExpiredNotification = (type) => {
    notification[type]({
      message: 'Session Expired',
      description: 'Your session has expired, please login again.',
    });
  };

  // useEffect(() => {
  //   const config = {
  //     method: 'get',
  //     url: `${BACKEND_URL}/bet-logs?${searchParams ? searchParams : ''}`,
  //     headers: headers,
  //     timeout: 60000,
  //   };
  //   const getAllData = async (config, currentStart) => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios(config);
  //       console.log(config.url);
  //       console.log(response.data);
  //       setCsvRawData((prevState) => [...prevState, ...response.data.data]);
  //       if (
  //         response.data.total > response.data.count &&
  //         response.data.data.length === response.data.count &&
  //         (+currentParams.count > response.data.count || !currentParams.count)
  //       ) {
  //         const newConfig = {
  //           method: 'get',
  //           url: `${BACKEND_URL}/bet-logs?start=${+currentStart + response.data.count}&count=${response.data.count}`,
  //           headers: headers,
  //           timeout: 60000,
  //         };
  //         // console.log(newConfig.url);
  //         getAllData(newConfig, `${+currentStart + response.data.count}`);
  //       } else {
  //         setIsLoading(false);
  //       }
  //     } catch (err) {
  //       // alert('Error');
  //       if (err.response.status === 401) {
  //         // alert('Session Timed Out');
  //         sessionExpiredNotification('error');
  //         navigate('/logout');
  //       }
  //     }
  //   };
  //   getAllData(config, currentParams.start ? currentParams.start : '0');
  // }, []);

  useEffect(() => {
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/bet-logs?${searchParams ? searchParams : ''}`,
      headers: headers,
      timeout: 60000,
    };
    console.log(config.url);
    setBetLogData([]);
    const getTableData = async () => {
      setIsLoading(true);
      try {
        console.log(config.url);
        const response = await axios(config);
        console.log(response);
        setBetLogData(response.data);
        setIsLoading(false);
      } catch (err) {
        // alert('Error');
        if (err.response.status === 401) {
          // alert('Session Timed Out');
          sessionExpiredNotification('error');
          navigate('/logout');
        }
      }
      setIsLoading(false);
    };
    getTableData();
  }, [searchParams]);
  console.log(betLogData);
  const csvData = csvRawData.map((item) => {
    return {
      id: item?.id,
      statusCode: item.statusCode,
      reqId: item.response.reqId,
      betOdd: item.response.betOdd,
      username: item.response.username,
      betAmount: item.response.betAmount,
      betStatus: item.response.betStatus,
      actionStatus: item.response.actionStatus,
      actionMessage: item.response.actionMessage,
      gold: item.params.gold,
      company: item.params.company,
      eventId: item.params.eventId,
      matchId: item.params.matchId,
      recordId: item.params.recordId,
      sportType: item.params.sportType,
      targetOdd: item.params.targetOdd,
      targetType: item.params.targetType,
      createdAt: moment(item.createdAt).format('DD-MM-YY HH:mm:ss'),
    };
  });

  return (
    <section id="betlog">
      <BetlogParameterInputs
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        currentParams={currentParams}
        csvData={csvData}
      />
      <BetLogTable
        data={betLogData}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isLoading={isLoading}
      />
    </section>
  );
};

export default BetLogPage;
