import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import {
  doGetAllBets,
} from "./thunk";

const betsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

const betSlice = createSlice({
  name: "bet",
  initialState: {
    isFetching: false,
    metadata: {},
    entities: [],
    ids: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetAllBets.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetAllBets.fulfilled, (state, action) => {
      const payload = action.payload;
      const bets = payload.data;
      const metadata = {
        count: payload.count,
        start: payload.start,
        total: payload.total,
      }
      state.isFetching = false;
      state.metadata = metadata;
      betsAdapter.setAll(state, bets);
    });
    builder.addCase(doGetAllBets.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const {
  selectAll: selectAllBets,
  selectById: selectBetById,
  selectIds: selectBetIds,
} = betsAdapter.getSelectors((state) => state.bet);

const { reducer: betReducer } = betSlice;
export default betReducer;
