import React from 'react';
import 'antd/dist/antd.css';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

const MatchesTable = ({ data, searchParams, setSearchParams, isLoading }) => {
  const currentLeagueList = data?.data?.map((item) => {
    return item.league;
  });
  const currentLeagueListUnique = currentLeagueList?.filter((v, i, a) => a.indexOf(v) === i);
  const leaguesFilter = currentLeagueListUnique?.map((item) => {
    return { text: item, value: item };
  });
  const setPagination = (pageNumber, pageSize) => {
    searchParams.set('start', (pageNumber - 1) * pageSize);
    searchParams.set('count', pageSize);
    setSearchParams(searchParams);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Match UUID',
      dataIndex: 'uuid',
      key: 'uuid',
      width: 100,
      sorter: (a, b) => a.uuid - b.uuid,
    },
    {
      title: 'League',
      dataIndex: 'league',
      key: 'league',
      width: 100,
      filters: leaguesFilter,
      sorter: (a, b) => a.league - b.league,
      onFilter: (value, record) => record.league.indexOf(value) === 0,
    },
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'host',
      width: 100,
    },
    {
      title: 'Guest',
      dataIndex: 'guest',
      key: 'guest',
      width: 100,
    },

    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 100,
      render: (number) => moment.unix(number).format('ddd D MMM YY HH:mm:ss'),
      sorter: (a, b) => a.startTime - b.startTime,
    },
    {
      title: 'Match Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: 'Details',
      dataIndex: 'id',
      key: 'details',
      width: 100,
      render: (id) => {
        return <Link to={`/details/${id}`}>Details</Link>;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (number) => moment(number).format('ddd D MMM YY HH:mm:ss'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 100,
      render: (number) => moment(number).format('ddd D MMM YY HH:mm:ss'),
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data.data}
      pagination={{
        total: data.total,
        defaultPageSize: 1000,
        pageSize: data.count,
        pageSizeOptions: [100, 200, 500, 1000],
        showQuickJumper: true,
        onChange: (pageNumber, pageSize) => {
          setPagination(pageNumber, pageSize);
          console.log(searchParams);
        },
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} entries`,
      }}
      rowKey="id"
      bordered
      size="middle"
      scroll={{
        y: 600,
      }}
      loading={isLoading}
    />
  );
};

export default MatchesTable;
