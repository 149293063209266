import React, { useState, useEffect } from "react";
import { Table, Input, Button, DatePicker } from "antd";
import { SearchOutlined, FilterFilled, CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  DATE_FORMAT_BACKEND,
  DATE_FORMAT_FRONTEND,
  PAGE_SIZE,
} from "../../constants";

function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
}

function getSortDirection(field, query) {
  const fieldName = Array.isArray(field) ? field.join("_") : field;
  const direction = query[`sorts[${fieldName}]`] + "end";
  if (direction === "ascend" || direction === "descend") {
    return direction;
  }
  return undefined;
}

function getFilteredValue(field, query) {
  const fieldName = Array.isArray(field) ? field.join("_") : field;
  const fieldValue = query[`filters[${fieldName}]`];
  return fieldValue ? [fieldValue] : null;
}

function getColumnSearchProps(type) {
  return {
    filterDropdown: (filterDropdownProps) => {
      const { setSelectedKeys, selectedKeys, confirm, clearFilters } =
        filterDropdownProps;
      return (
        <div style={{ padding: 8 }}>
          {type === "date" && (
            <DatePicker
              style={{ width: 188, marginBottom: 8, display: "block" }}
              format={DATE_FORMAT_FRONTEND}
              value={
                selectedKeys[0]
                  ? moment(selectedKeys[0], DATE_FORMAT_BACKEND)
                  : null
              }
              onChange={(e) =>
                setSelectedKeys(e ? [e.format(DATE_FORMAT_BACKEND)] : [])
              }
            />
          )}
          {type !== "date" && (
            <Input
              placeholder="Search"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={confirm}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
          )}
          <Button
            size="small"
            style={{ width: 90, marginRight: 8 }}
            onClick={clearFilters}
          >
            Reset
          </Button>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            onClick={confirm}
          >
            Search
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) =>
      type === "date" ? (
        <FilterFilled style={{ color: filtered ? "#050593" : undefined }} />
      ) : (
        <SearchOutlined style={{ color: filtered ? "#050593" : undefined }} />
      ),
  };
}

const BaseTable = ({
  bulkUpdate,
  pagination,
  query = {},
  columns = [],
  onChange2,
  modifying,
  defaultSelectedItems,
  getCheckboxProps,
  ...props
}) => {
  const [state, setState] = useState({ selection: {} });

  useEffect(() => {
    if (!modifying) {
      setState({ selection: {} });
    }
  }, [modifying]);

  useEffect(() => {
    if (defaultSelectedItems && props.dataSource) {
      setState({
        selection: {
          ...state.selection,
          [pagination.current]: defaultSelectedItems,
        },
      });
    }
  }, [defaultSelectedItems, props.dataSource]);

  function handleTableChange(pagination, filter, sorter) {
    console.log('pagination ', pagination);
    const nextQuery = {
      currentPage: pagination.current,
      count: pagination.count,
    };
    if (sorter.order && sorter.field) {
      const sortField = `sorts[${
        Array.isArray(sorter.field) ? sorter.field.join("_") : sorter.field
      }]`;
      const sortValue = sorter.order.replace("end", "");
      nextQuery[sortField] = sortValue;
    }
    for (const field of Object.keys(filter)) {
      if (filter[field]) {
        const filterField = `filter[${field.replace(".", "_")}]`;
        let filterValue = filter[field];
        if (Array.isArray(filterValue)) {
          filterValue = filterValue.join(",").trim();
        } else {
          filterValue =
            typeof filterValue === "string" ? filterValue.trim() : filterValue;
        }
        nextQuery[filterField] = filterValue;
      }
    }
    onChange2 && onChange2(nextQuery);
  }

  const COLUMNS = columns.map((colProps) => {
    const { searchable, ...rest } = colProps;
    return {
      ...rest,
      ...(rest.sorter
        ? { sortOrder: getSortDirection(rest.dataIndex, query) }
        : {}),
      ...(rest.filters || searchable
        ? { filteredValue: getFilteredValue(rest.dataIndex, query) }
        : {}),
      ...(searchable ? getColumnSearchProps(searchable) : {}),
    };
  });

  if (bulkUpdate) {
    COLUMNS.splice(0, 0, {
      key: "-",
      fixed: "left",
      width: 1,
    });
  }

  const selectionPopover =
    bulkUpdate && bulkUpdate(flatten(Object.values(state.selection)));

  const rowSelection = bulkUpdate && {
    selectedRowKeys: flatten(Object.values(state.selection)).map(
      (row) => row._id
    ),
    onChange: (_, selectedRows) => {
      setState({
        selection: {
          ...state.selection,
          [pagination.currentPage]: selectedRows.filter((s) => s),
        },
      });
    },
    columnWidth: 40,
    getCheckboxProps: getCheckboxProps,
  };

  const paginationProps = {
    showSizeChanger: false,
    currentPage: Number(query.currentPage) || 1,
    count: Number(query.count) || PAGE_SIZE,
    ...pagination,
  };

  return (
    <div className="table-base-wrapper">
      {selectionPopover && (
        <div className="selection-popover">
          <span className="text-selected">
            {selectionPopover.title}
            {selectionPopover.title && (
              <Button
                size="small"
                type="link"
                icon={<CloseOutlined />}
                className="close"
                onClick={() => setState({ selection: {} })}
                disabled={rowSelection.selectedRowKeys.length <= 0}
              />
            )}
          </span>
          {selectionPopover.actions}
        </div>
      )}
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        showSorterTooltip={false}
        pagination={paginationProps}
        columns={COLUMNS}
        scroll={{ x: 2200 }}
        onChange={handleTableChange}
        {...props}
      />
    </div>
  );
};

export default BaseTable;
