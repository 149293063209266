import axios from 'axios';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../constants';

export default async function (
  endpoint,
  opts= {},
  withData = false,
  baseURL = process.env.REACT_APP_BACKEND_URL,
) {
  const { headers, ...options } = opts;
  const tokenCookie = Cookies.get(TOKEN_NAME);
  const authToken = tokenCookie ? JSON.parse(tokenCookie).authToken : undefined;
  
  const config = Object.assign(
    {
      url: endpoint,
      baseURL,
      headers: Object.assign(
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        headers,
      ),
      timeout: 60000,
    },
    options,
  );
  try {
    const response = await axios.request(config);
    return { response: (withData ? response.data : response) || {} };
  } catch (error) {
    const err = error;
    if (err.response) {
      if (err.response.data.data) {
        return {
          error: Object.assign(err.response.data.data, {
            status: err.response.status || err.request?.status,
            message: err.response.statusText || err.request?.statusText,
          }),
        };
      } else {
        return {
          error: Object.assign(err.response.data, {
            status: err.response.status || err.request?.status,
            message: err.response.statusText || err.request?.statusText,
          }),
        };
      }
    }
    if (err.request && err.request._response) {
      return { error: { message: err.request._response } };
    }
    return { error: { message: err.message } };
  }
}
