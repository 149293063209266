import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { doLogout } from "../stores/slice";

export default () => {
  class Container extends React.Component {
    componentDidMount() {
      const { authenticated, doLogout } = this.props;
      authenticated && doLogout({});
    }
    render() {
      return this.props.authenticated ? null : <Navigate to="/login" />;
    }
  }
  function mapStateToProps(state) {
    const {
      auth: { authenticated },
    } = state;
    return {
      authenticated,
    };
  }
  return connect(mapStateToProps, {
    doLogout,
  })(Container);
};
