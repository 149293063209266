import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const doGetUserBalance = createAsyncThunk(
  "companies/doGetUserBalance",
  async (params, thunkAPI) => {
    console.log('haha');
    try {
      const { response, error } = await api.doGetUserBalance(params);
      return error ? thunkAPI.rejectWithValue(error) : response.data;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  }
);

