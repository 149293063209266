export const APP_NAME = 'cr7';
export const TOKEN_NAME = '_cr7_session';
export const REACT_APP_ROOT_PATH = process.env.REACT_APP_ROOT_PATH || '';

export const PAGE_SIZE = 100;
export const TIME_FORMAT_FRONTEND = "HH:mm";
export const DATE_FORMAT_FRONTEND = "ddd, DD MMM, YYYY";
export const DATETIME_FORMAT_FRONTEND = "ddd, DD MMM, YYYY hh:mm A";
export const DATE_FORMAT_BACKEND = "YYYY/MM/DD";
export const DATE_FORMAT_QUERY = "YYYY-MM-DD";

export const BOOKIE_LIST = [
  {name: 'BETFAIR', value: 'BETFAIR' },
  {name: 'CROWN', value: 'CROWN' },
  {name: 'GA288', value: 'GA288' },
  {name: 'IBC', value: 'IBC' },
  {name: 'ISN', value: 'ISN' },
  {name: 'MATCHBOOK', value: 'MATCHBOOK' },
  {name: 'PENTA88', value: 'PENTA88' },
  {name: 'PIN88', value: 'PIN88' },
  {name: 'SBC', value: 'SBC' },
  {name: 'SBO', value: 'SBO' },
  {name: 'WLG', value: 'WLG' },
]