import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { doVerifyOtp } from "../stores/thunk";

export default (Component) => {
  
  class Container extends React.Component {
    render() {
      const { auth } = this.props;
      console.log('auth ', auth);
      return auth.authenticated && auth.authToken && auth.verified  ? (
        <Navigate to={process.env.REACT_APP_ROOT_PATH || '/'} />
      ) : (
        <Component {...this.props} />
      );
    }
  }
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      verifyToken: state.auth.token,
    };
  }
  return connect(mapStateToProps, { doVerifyOtp })(
    Container
  );
};
