import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { headerConfig } from '../utils/header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CalculationTable from '../Components/Bets/CalculationTable';
import BetsParameterInputs from '../Components/Bets/BetsParameterInputs';
import BetsTable from '../Components/Bets/BetsTable';
import '../Components/Bets/Bets.css';
import { Spin, notification } from 'antd';
import EdgeWinGraph from '../Components/Bets/EdgeWinGraph';
import { calculateEdge, calculateWinnings, givenOdds } from '../constants/calculations';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

//comment to test deployment time
const BetsPage = () => {
  const navigate = useNavigate();
  const headers = headerConfig();
  const [betsData, setBetsData] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const currentParams = Object.fromEntries([...searchParams]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchValidBets, setFetchValidBets] = useState(true);
  const bookieList = [
    'BETFAIR',
    'CROWN',
    'GA288',
    'IBC',
    'ISN',
    'MATCHBOOK',
    'PENTA88',
    'PIN88',
    'SBC',
    'SBO',
    'WLG',
  ].sort((a, b) => a.localeCompare(b));

  const sessionExpiredNotification = (type) => {
    notification[type]({
      message: 'Session Expired',
      description: 'Your session has expired, please login again.',
    });
  };

  // useEffect(() => {
  //   if (fetchValidBets) {
  //     searchParams.set('filter[validBets]', 'true');
  //   } else {
  //     searchParams.delete('filter[validBets]');
  //   }
  //   const config = {
  //     method: 'get',
  //     url: `${BACKEND_URL}/bets?${searchParams ? searchParams : ''}`,
  //     headers: headers,
  //     timeout: 60000,
  //   };
  //   setCsvData([]);
  //   const getCsvData = async (config, currentStart) => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios(config);
  //       console.log(config.url);
  //       console.log(response.data);
  //       setCsvData((prevState) => prevState.concat(response.data.data));
  //       setIsLoading(false);
  //       if (
  //         response.data.total > response.data.count &&
  //         response.data.data.length === response.data.count &&
  //         (+currentParams.count > response.data.count || !currentParams.count)
  //       ) {
  //         const newConfig = {
  //           method: 'get',
  //           url: `${BACKEND_URL}/bets?${fetchValidBets ? 'filter[validBets]=true&' : ''}start=${
  //             +currentStart + 1000
  //           }&count=1000${currentParams['filter[source]'] ? `&filter[source]=` + currentParams['filter[source]'] : ''}${
  //             currentParams['filter[league]'] ? `&filter[league]=` + currentParams['filter[league]'] : ''
  //           }${
  //             currentParams['filter[createdAtFrom]']
  //               ? `&filter[createdAtFrom]=` + currentParams['filter[createdAtFrom]']
  //               : ''
  //           }${
  //             currentParams['filter[createdAtTo]'] ? `&filter[createdAtTo]=` + currentParams['filter[createdAtTo]'] : ''
  //           }${currentParams['filter[status]'] ? `&filter[status]=` + currentParams['filter[status]'] : ''}`,
  //           headers: headers,
  //           timeout: 60000,
  //         };
  //         // console.log(newConfig.url);
  //         getCsvData(newConfig, `${+currentStart + response.data.count}`);
  //       } else {
  //         setIsLoading(false);
  //       }
  //     } catch (err) {
  //       if (err.response.status === 401) {
  //         sessionExpiredNotification('error');
  //         navigate('/logout');
  //       }
  //     }
  //   };
  //   getCsvData(config, currentParams.start || '0');
  // }, []);

  useEffect(() => {
    if (fetchValidBets) {
      searchParams.set('filter[validBets]', 'true');
    } else {
      searchParams.delete('filter[validBets]');
    }
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/bets?${searchParams ? searchParams : ''}`,
      headers: headers,
      timeout: 60000,
    };

    console.log(config.url);
    const getTableData = async () => {
      setIsLoading(true);
      try {
        const response = await axios(config);
        console.log(config.url);
        console.log(response.data);
        setBetsData(response.data);
        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          sessionExpiredNotification('error');
          navigate('/logout');
        }
      }
    };
    getTableData();
  }, [searchParams, fetchValidBets]);

  // const removeDuplicateEntries = (array) => {
  //   return [...new Map(array?.map((item) => [item['id'], item])).values()];
  // };
  // const allBets = removeDuplicateEntries(betsData.data);
  // const validBets = betsData.data?.filter((item) => item.unityId && item.unityId != 'not_found');

  // const earliestMatchDate = moment
  //   .unix(betsData.data[betsData?.count- 1]?.match.startTime)
  //   .format('ddd D MMM YY');
  // const latestMatchDate = moment.unix(betsData.data[0]?.match.startTime).format('ddd D MMM YY');

  // const tableData = validBets?.map((item, index) => {
  //   return {
  //     key: index,
  //     id: item?.id,
  //     startTime: item?.match?.startTime,
  //     source: item?.source,
  //     league: item?.match?.league,
  //     host: item?.match?.host,
  //     guest: item?.match?.guest,
  //     homeTrueOdd: item?.homeTrueOdd,
  //     drawTrueOdd: item?.drawTrueOdd,
  //     awayTrueOdd: item?.awayTrueOdd,
  //     homeGivenOdds: item?.odd.rateOver,
  //     drawGivenOdds: item?.odd.rateEqual,
  //     awayGivenOdds: item?.odd.rateUnder,
  //     selected: item?.selected,
  //     winner: item?.winners ? item?.winners : 'Pending',
  //     givenOdds: givenOdds(item),
  //     edge: +(calculateEdge(item) * 100).toFixed(2),
  //     amount: item?.amount,
  //     expectedWinnings: +(calculateEdge(item) * item.amount).toFixed(2),
  //     status: item?.match?.status,
  //     details: item?.matchId,
  //     betResult: item?.winners != null ? (item?.winners === item?.selected ? 'Win' : 'Loss') : 'Pending',
  //     net: item?.selected === item?.winners ? +calculateWinnings(item).toFixed(2) : -item?.amount,
  //     createdAt: item?.createdAt,
  //     updatedAt: item?.updatedAt,
  //     unityId: item?.unityId,
  //     valid: item?.unityId && item?.unityId != 'not_found' ? 'Yes ✅' : 'No ❌',
  //   };
  // });

  const allDataParsed = betsData.data?.map((item, index) => {
    return {
      key: index,
      id: item?.id,
      startTime: item?.match?.startTime,
      source: item?.source,
      league: item?.match?.league,
      host: item?.match?.host,
      guest: item?.match?.guest,
      homeTrueOdd: item?.homeTrueOdd,
      drawTrueOdd: item?.drawTrueOdd,
      awayTrueOdd: item?.awayTrueOdd,
      homeGivenOdds: item?.odd.rateOver,
      drawGivenOdds: item?.odd.rateEqual,
      awayGivenOdds: item?.odd.rateUnder,
      selected: item?.selected,
      winner: item?.winners ? item?.winners : 'Pending',
      givenOdds: givenOdds(item),
      edge: +(calculateEdge(item) * 100).toFixed(2),
      amount: item?.amount,
      expectedWinnings: +(calculateEdge(item) * item.amount).toFixed(2),
      status: item?.match?.status,
      details: item?.matchId,
      betResult: item?.winners != null ? (item?.winners === item?.selected ? 'Win' : 'Loss') : 'Pending',
      net: item?.selected === item?.winners ? +calculateWinnings(item).toFixed(2) : -item?.amount,
      createdAt: item?.createdAt,
      updatedAt: item?.updatedAt,
      unityId: item?.unityId,
      valid: item?.unityId && item?.unityId != 'not_found' ? 'Yes ✅' : 'No ❌',
    };
  });

  const csvParse = (data) =>
    data?.map((item) => {
      return {
        ...item,
        winner: item.winner,
        startTime: moment.unix(item.startTime).format('DD-MM-YY HH:mm:ss'),
        createdAt: moment(item.createdAt).format('DD-MM-YY HH:mm:ss'),
        updatedAt: moment(item.updatedAt).format('DD-MM-YY HH:mm:ss'),
        unityId: item.unityId,
      };
    });

  //Temporary Solution for loading display
  const loadingMessage = (isLoading) => {
    return isLoading ? (
      <div style={{ display: 'flex' }}>
        <p>Data Loading</p>
        <Spin size="small" />
      </div>
    ) : (
      <p>Data Loaded</p>
    );
  };

  // const dispatch = useDispatch();
  return (
    <section id="bets">
      {/* <button onClick={() => dispatch(doLogout())}>test</button> */}
      <BetsParameterInputs
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        currentParams={currentParams}
        bookieList={bookieList}
        // csvValidData={csvParse(tableData)}
        csvAllData={csvParse(allDataParsed)}
        fetchValidBets={fetchValidBets}
        setFetchValidBets={setFetchValidBets}
        betsData={betsData?.data}
      />
      {/* <EdgeWinGraph bets={betsData} calculateEdge={calculateEdge} /> */}
      {/* {loadingMessage(isLoading)} */}
      <CalculationTable searchParams={searchParams} isLoading={isLoading}/>

      <br />
      {/* {earliestMatchDate != 'Invalid date'
        ? `Match Dates: ${earliestMatchDate} - ${latestMatchDate}`
        : 'No entries found'} */}
      <BetsTable data={betsData} searchParams={searchParams} setSearchParams={setSearchParams} isLoading={isLoading}/>
    </section>
  );
};

export default BetsPage;
