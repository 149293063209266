import moment from "moment";
import { DATETIME_FORMAT_FRONTEND, DATE_FORMAT_FRONTEND } from "../constants";
import * as qs from 'qs';

export const handleFilterParams = (params) => {
  return qs.stringify(params, { encode: false, skipNulls: true });
};

export const DateTimeFormat = (datetime) => {
  if (datetime === null) {
    return;
  }
  return moment(datetime).local().format('ddd D MMM YY HH:mm:ss');
};

export const DateFormat = (date) => {
  if (date === null) {
    return;
  }
  return moment(date).format(DATE_FORMAT_FRONTEND);
};

export const NumberToDateTime = (number) => {
  if (number === null) return;
  return moment.unix(number).format(DATETIME_FORMAT_FRONTEND);
};

export const humanizeText = (text) => {
  return text?.replace(/[_]/g, ' ');
};