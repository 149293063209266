import React from "react";
import { Layout } from "antd";

import MainHeader from "../Header/Main";
import "./Main.scss";
import SiderContent from "./SiderContent";

const { Content } = Layout;

const MainLayout = ({ children, auth, userBalance }) => {
  const { user } = auth || {};
  return (
    <Layout className="MainLayout">
      <SiderContent user={user} />
      <Content className="site-layout">
        <MainHeader user={user} userBalance={userBalance} />
        {children}
      </Content>
  </Layout>
  );
};

export default MainLayout;
