import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import {
  doGetAllLeagues,
} from "./thunk";

const leaguesAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

const leagueSlice = createSlice({
  name: "league",
  initialState: {
    isFetching: false,
    metadata: {},
    entities: [],
    ids: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetAllLeagues.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetAllLeagues.fulfilled, (state, action) => {
      const payload = action.payload;
      const leagues = payload.data;
      const metadata = {
        count: payload.count,
        start: payload.start,
        total: payload.total,
      }
      state.isFetching = false;
      state.metadata = metadata;
      leaguesAdapter.setAll(state, leagues);
    });
    builder.addCase(doGetAllLeagues.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const {
  selectAll: selectAllLeagues,
  selectById: selectLeagueById,
  selectIds: selectLeagueIds,
} = leaguesAdapter.getSelectors((state) => state.league);

const { reducer: leagueReducer } = leagueSlice;
export default leagueReducer;
