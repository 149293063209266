import React, { Component } from "react";
import { notification, Typography } from "antd";

import ErrorContainer from "../modules/error/container";

const { Text } = Typography;

const Description = ({ errors }) => (
  <div className="notification-description">
    {errors?.map((msg, index) => {
      return (
        <p key={index}>
          <Text type="danger">{msg}</Text>
        </p>
      );
    })}
  </div>
);

class AppError extends Component {
  componentDidUpdate(prevProps) {
    const {
      error: { message, errors, status },
    } = this.props;
    if (errors !== prevProps?.error?.errors && errors) {
      notification.error({
        message: message,
        description: errors && <Description errors={errors} />,
        placement: "bottomRight",
        onClose: () => this.props.setError({}),
      });
    }

    if (status === 401) {
      this.props.doLogout({});
    }
  }
  render() {
    return null;
  }
}

export default ErrorContainer(AppError);
