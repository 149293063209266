import 'antd/dist/antd.css';
import { Table, Button, Form, notification, Input, Radio, Tag, Space } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { headerConfig } from '../../utils/header';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const LeaguesTable = ({ data, setLeaguesData, searchParams, setSearchParams, isLoading }) => {
  const navigate = useNavigate();
  const headers = headerConfig();
  const [form] = Form.useForm();
  const setPagination = (pageNumber, pageSize) => {
    searchParams.set('start', (pageNumber - 1) * pageSize);
    searchParams.set('count', pageSize);
    setSearchParams(searchParams);
  };

  const sessionExpiredNotification = (type) => {
    notification[type]({
      message: 'Session Expired',
      description: 'Your session has expired, please login again.',
    });
  };

  const patchNotification = ({ type, message, description }) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const changeAcceptBets = async (values, newValue) => {
    console.log(values);
    let data = JSON.stringify({
      acceptBet: newValue,
    });
    const config = {
      method: 'patch',
      url: `${BACKEND_URL}/leagues/${values.id}`,
      headers,
      data,
    };
    try {
      const response = await axios(config);
      console.log(response);
      patchNotification({
        type: 'success',
        message: 'Update Successful',
        description: `${values.name} updated to ${newValue ? 'accept bets' : 'not accept bets'}`,
      });
      setLeaguesData((prevState) => {
        return {
          ...prevState,
          data: prevState.data.map((item) => {
            if (item.id === values.id) {
              return { ...item, acceptBet: newValue };
            } else return item;
          }),
        };
      });
    } catch (err) {
      if (err.response.status === 401) {
        patchNotification({
          type: 'warning',
          message: 'Update unsuccessful',
          description: `Update did not succeed, please try again.`,
        });
        sessionExpiredNotification('error');
        navigate('/logout');
      }
    }
  };

  const changeBetAmount = async (values, newValue) => {
    console.log(values);
    let data = JSON.stringify({
      betAmount: newValue,
    });
    const config = {
      method: 'patch',
      url: `${BACKEND_URL}/leagues/${values.id}`,
      headers,
      data,
    };
    try {
      const response = await axios(config);
      console.log(response);
      patchNotification({
        type: 'success',
        message: 'Update Successful',
        description: `${values.name} updated with bet amount of $${newValue}`,
      });
      setLeaguesData((prevState) => {
        return {
          ...prevState,
          data: prevState.data.map((item) => {
            if (item.id === values.id) {
              return { ...item, betAmount: newValue };
            } else return item;
          }),
        };
      });
    } catch (err) {
      patchNotification({
        type: 'warning',
        message: 'Update unsuccessful',
        description: `Update did not succeed, please try again.`,
      });

      if (err.response.status === 401) {
        sessionExpiredNotification('error');
        navigate('/logout');
      }
    }
  };

  const parsedBets = data?.data?.map((item) => {
    return { ...item, ...item.roiInfo };
  });

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: 60,
    //   align: 'center',

    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'Accept Bets',
      width: 140,
      align: 'center',
      render: (row) => (
        <Form>
          <Form.Item name="acceptBet" className="acceptBet" initialValue={`${row.acceptBet}`}>
            <Radio.Group value={`${row.acceptBet}`}>
              <Radio.Button onClick={() => changeAcceptBets(row, true)} value="true">
                Yes
              </Radio.Button>
              <Radio.Button onClick={() => changeAcceptBets(row, false)} value="false">
                No
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: 'Bet Amount',
      key: 'betAmount',
      width: 70,
      align: 'center',
      render: (row) => (row.acceptBet ? (row.betAmount ? `$${row.betAmount}` : '$11') : 'N/A'),
    },
    {
      title: 'Collect Odds',
      dataIndex: 'collectOdds',
      key: 'collectOdds',
      width: 70,
      align: 'center',
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Change Bet Amount',
      width: 100,
      align: 'center',
      render: (row) => (
        <Form form={form}>
          <Form.Item name={`${row.id}`} initialValue={row.betAmount || 0}>
            <Input type="number" min={0} />
          </Form.Item>
          <Button onClick={() => changeBetAmount(row, +form.getFieldValue(`${row.id}`))}>Change</Button>
        </Form>
      ),
    },
    {
      title: 'Total Bet Amount',
      dataIndex: 'totalAmountRisk',
      key: 'totalAmountRisk',
      width: 70,
      align: 'center',
      sorter: (a, b) => a.totalAmountRisk - b.totalAmountRisk,
      render: (value) => (value ? `${value >= 0 ? '$' : '-$'}${Math.abs(value).toFixed(2)}` : 'N/A'),
    },
    {
      title: 'Winnings',
      dataIndex: 'sumOfActualWinnings',
      key: 'sumOfActualWinnings',
      width: 70,
      align: 'center',
      sorter: (a, b) => a.sumOfActualWinnings - b.sumOfActualWinnings,
      render: (value) => (value ? `${value >= 0 ? '$' : '-$'}${Math.abs(value).toFixed(2)}` : 'N/A'),
    },
    {
      title: 'ROI',
      dataIndex: 'actualROI',
      key: 'actualROI',
      width: 70,
      align: 'center',
      sorter: (a, b) => a.actualROI - b.actualROI,
      render: (value) => (value ? `${(value * 100).toFixed(2)}%` : 'N/A'),
    },
    // {
    //   title: 'Created At',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   width: 90,
    //   render: (number) => moment(number).format('ddd D MMM YY HH:mm:ss'),
    //   sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    // },
    // {
    //   title: 'Updated At',
    //   dataIndex: 'updatedAt',
    //   key: 'updatedAt',
    //   width: 90,
    //   render: (number) => moment(number).format('ddd D MMM YY HH:mm:ss'),
    //   sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    // },
  ];

  return (
    <Table
      columns={columns}
      dataSource={parsedBets}
      rowKey="id"
      pagination={{
        total: data.total,
        defaultPageSize: 100,
        pageSizeOptions: [100, 200, 500],
        showQuickJumper: true,
        onChange: (pageNumber, pageSize) => {
          setPagination(pageNumber, pageSize);
          console.log(searchParams);
        },
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} entries`,
      }}
      size="middle"
      scroll={{
        y: 600,
      }}
      loading={isLoading}
    />
  );
};

export default LeaguesTable;
