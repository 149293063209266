import React from 'react';
import 'antd/dist/antd.css';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import './Bets.scss';
import { actualWinnings, calculateEdge, expectedWinnings, givenOdds } from '../../constants/calculations';

const BetsTable = ({ data, searchParams, setSearchParams, isLoading }) => {
  const setPagination = (pageNumber, pageSize) => {
    searchParams.set('start', (pageNumber - 1) * pageSize);
    searchParams.set('count', pageSize);
    setSearchParams(searchParams);
  };

  const dataParsed = data.data?.map((item) => {
    return {
      ...item.match,
      ...item.odd,
      ...item,
      edge: calculateEdge(item),
      givenOdds: givenOdds(item),
      expectedWinnings: expectedWinnings(item),
      betResult: item?.winners != null ? (item?.winners === item?.selected ? 'Win' : 'Loss') : 'Pending...',
      net: actualWinnings(item),
    };
  });

  console.log('parsed', dataParsed);

  const sortParser = (string) => {
    switch (string) {
      case 'ascend':
        return 'ASC';
      case 'descend':
        return 'DESC';
      default:
        return null;
    }
  };

  const columns = [
    {
      title: <div style={{ textAlign: 'center' }}>Start Time</div>,
      dataIndex: 'startTime',
      key: 'startTime',
      width: 130,
      render: (number) => moment.unix(number).format('ddd D MMM YY HH:mm:ss'),
      sorter: (a, b) => a.startTime - b.startTime,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Created At</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 130,
      render: (number) => moment(number).format('ddd D MMM YY HH:mm:ss'),
      sorter: (a, b) => moment(a.createdAt).format('x') - moment(b.createdAt).format('x'),
    },
    {
      title: <div style={{ textAlign: 'center' }}>League</div>,
      dataIndex: 'league',
      key: 'league',
      width: 200,
      onFilter: (value, record) => record.league.indexOf(value) === 0,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Home</div>,
      dataIndex: 'host',
      key: 'host',
      width: 200,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Away</div>,
      dataIndex: 'guest',
      key: 'guest',
      width: 200,
    },
    {
      title: <div style={{ textAlign: 'center' }}>True Odds</div>,
      width: 150,
      children: [
        {
          title: 'Home',
          dataIndex: 'homeTrueOdd',
          key: 'homeTrueOdd',
          width: 70,
          align: 'center',
        },
        {
          title: 'Draw',
          dataIndex: 'drawTrueOdd',
          key: 'drawTrueOdd',
          width: 70,

          align: 'center',
        },
        {
          title: 'Away',
          dataIndex: 'awayTrueOdd',
          key: 'awayTrueOdd',
          width: 70,

          align: 'center',
        },
      ],
    },
    {
      title: 'Selection',
      dataIndex: 'selected',
      key: 'selection',
      width: 100,
      align: 'center',
    },
    {
      title: 'Edge',
      dataIndex: 'edge',
      key: 'edge',
      width: 100,
      align: 'center',
      render: (number) => `${(number * 100).toFixed(2)}%`,
      sorter: (a, b) => a.edge - b.edge,
    },
    {
      title: 'Winner',
      dataIndex: 'winners',
      key: 'winners',
      width: 100,
      align: 'center',
      render: (winner) => `${winner || 'Pending...'}`,
    },
    {
      title: 'Bookie',
      dataIndex: 'source',
      key: 'source',
      width: 100,
      align: 'center',
    },
    {
      title: 'Given (O)',
      dataIndex: 'givenOdds',
      key: 'givenOdds',
      width: 100,
      align: 'center',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 80,
      align: 'center',
      render: (amount) => `$${amount.toFixed(2)}`,
    },
    {
      title: 'Expected Winning',
      dataIndex: 'expectedWinnings',
      key: 'expectedWinnings',
      width: 100,
      align: 'center',
      render: (amount) => {
        if (amount != undefined) {
          return amount >= 0 ? `$${amount.toFixed(2)}` : `-$${Math.abs(amount.toFixed(2))}`;
        }
      },
      sorter: (a, b) => a.expectedWinnings - b.expectedWinnings,
    },
    {
      title: 'Bet Result',
      dataIndex: 'betResult',
      key: 'betResult',
      width: 100,
      align: 'center',
    },
    {
      title: 'Net',
      dataIndex: 'net',
      key: 'net',
      width: 80,
      align: 'center',
      render: (amount, value) =>
        value.winners ? (amount ? `${amount >= 0 ? '$' : '-$'}${Math.abs(amount).toFixed(2)}` : 'N/A') : 'Pending...',

      sorter: (a, b) => a.net - b.net,
    },
    // {
    //   title: "Valid",
    //   dataIndex: "valid",
    //   key: "valid",
    //   width: 80,
    //   fixed: "left",
    //   align: "center",
    // },
  ];
  return (
    <Table
      pagination={{
        total: data.total,
        defaultPageSize: 1000,
        pageSizeOptions: [50, 100, 200, 1000],
        showQuickJumper: true,
        onChange: (pageNumber, pageSize) => {
          setPagination(pageNumber, pageSize);
          console.log(searchParams);
        },
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} entries`,
      }}
      columns={columns}
      dataSource={dataParsed}
      loading={isLoading}
      rowKey="id"
      bordered
      size="middle"
      scroll={{
        y: '75vh',
      }}
      onChange={(current, league, column, currentDataSource) => {
        console.log(column);
        if (column.order) {
          searchParams.set(`sort[${column.field}]`, sortParser(column.order));
          setSearchParams(searchParams);
        } else {
          searchParams.delete(`sort[${column.field}]`);
          setSearchParams(searchParams);
        }
      }}
    />
  );
};

export default BetsTable;
