import { CSVLink } from 'react-csv';
import { Form, Button, Collapse } from 'antd';

import Input from 'antd/lib/input/Input';
import useDebounce from '../../CustomHooks/useDebounce';
import { useState } from 'react';
const { Panel } = Collapse;

const BetlogParameterInputs = ({ searchParams, setSearchParams, currentParams, csvData }) => {
  const [matchUuid, setMatchUuid] = useState('');
  const [betUuid, setBetUuid] = useState('');
  const [form] = Form.useForm();

  const setParameters = (parameter, value) => {
    value ? searchParams.set(parameter, value) : searchParams.delete(parameter);
    searchParams.delete('start');
    setSearchParams(searchParams);
  };
  useDebounce(() => setParameters('filter[matchUuid]', matchUuid), 300, [matchUuid]);
  useDebounce(() => setParameters('filter[betUuid]', betUuid), 300, [betUuid]);

  const resetParams = () => {
    setSearchParams({});
    form.resetFields();
    form.setFieldValue('matchUuid', '');
    form.setFieldValue('betUuid', '');
  };

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header="Search Options" key="1">
        <Form form={form} colon={false}>
          <Form.Item
            label="Match UUID"
            name="matchUuid"
            initialValue={currentParams['filter[matchUuid]'] ? currentParams['filter[matchUuid]'] : ''}
            id="betMatchIdParam"
          >
            <Input
              size="medium"
              value={matchUuid}
              onChange={(e) => {
                setMatchUuid(e.target.value);
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            label="Bet UUID"
            name="betUuid"
            initialValue={currentParams['filter[betUuid]'] ? currentParams['filter[betUuid]'] : ''}
            id="betMatchIdParam"
          >
            <Input
              size="medium"
              value={betUuid}
              onChange={(e) => {
                setBetUuid(e.target.value);
              }}
            ></Input>
          </Form.Item>
          <CSVLink data={csvData || []}>
            <Button>Download CSV</Button>
          </CSVLink>
          <Button onClick={() => resetParams()}>Reset</Button>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default BetlogParameterInputs;
