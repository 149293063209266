export  const calculateEdge = (bet) => {
  switch (bet.selected) {
    case 'HOME':
      return (bet.odd.rateOver - bet.homeTrueOdd) / bet.homeTrueOdd;
    case 'AWAY':
      return (bet.odd.rateUnder - bet.awayTrueOdd) / bet.awayTrueOdd;
    case 'DRAW':
      return (bet.odd.rateEqual - bet.drawTrueOdd) / bet.drawTrueOdd;
  }
};

export const givenOdds = (bet) => {
  switch (bet.selected) {
    case 'HOME':
      return bet.odd.rateOver;
    case 'AWAY':
      return bet.odd.rateUnder;
    case 'DRAW':
      return bet.odd.rateEqual;
  }
};

export const calculateWinnings = (bet) => {
  switch (bet.selected) {
    case 'HOME':
      return bet.odd.rateOver * bet.amount - bet.amount;
    case 'AWAY':
      return bet.odd.rateUnder * bet.amount - bet.amount;
    case 'DRAW':
      return bet.odd.rateEqual * bet.amount - bet.amount;
  }
};