import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../constants';

export function headerConfig() {
  const tokenCookie = Cookies.get(TOKEN_NAME);
  const authToken = tokenCookie && JSON.parse(tokenCookie).authToken || undefined;
  const headers = Object.assign({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
  });

  return headers;
}
