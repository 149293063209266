import { combineReducers } from "@reduxjs/toolkit";

import commonReducer from "./slice";
import authReducer from "../modules/auth/stores/slice";
import betReducer from "../modules/bet/stores/slice";
import userBalanceReducer from "../modules/userBalance/stores/slice"
import leagueReducer from "../modules/league/stores/slice";

const appReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  bet: betReducer,
  userBalance: userBalanceReducer,
  league: leagueReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/doLogout") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
