import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import './Main.scss';
import withRouter from '../../CustomHooks/withRouter';

const MainMenu = ({ location }) => {
  const { pathname } = location;

  let selectedKey = pathname;

  if (selectedKey.includes('/betlog')) {
    selectedKey = '/betlog';
  } else if (selectedKey.includes('/matches')) {
    selectedKey = '/matches';
  } else {
    selectedKey = '/';
  }

  const menuItems = [
    {
      key: 'bets',
      label: <Link to="/">Bets</Link>,
    },
    {
      key: 'matches',
      label: <Link to="/matches">Matches</Link>,
    },
    {
      key: 'betlog',
      label: <Link to="/betlog">Betlog</Link>,
    },
    {
      key: 'leagues',
      label: <Link to="/leagues">Leagues</Link>,
    },
  ];

  return (
    <Menu
      className="main-menu"
      triggerSubMenuAction="hover"
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      items={menuItems}
    />
  );
};

export default withRouter(MainMenu);
