import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MatchesTable from '../Components/Matches/MatchesTable';
import { Form, notification } from 'antd';
import { headerConfig } from '../utils/header';
import MatchesParametersInput from '../Components/Matches/MatchesParametersInput';
import '../Components/Matches/Matches.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const MatchesPages = () => {
  const navigate = useNavigate();
  const headers = headerConfig();
  const [matchesData, setMatchesData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [searchParams, setSearchParams] = useSearchParams({});
  const currentParams = Object.fromEntries([...searchParams]);
  const [leagueList, setLeagueList] = useState([currentParams['filter[league]']]);
  const [form] = Form.useForm();

  const sessionExpiredNotification = (type) => {
    notification[type]({
      message: 'Session Expired',
      description: 'Your session has expired, please login again.',
    });
  };

  useEffect(()=>{
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/leagues?`,
      headers: headers,
      timeout: 60000,
    };
  }, [])

  // useEffect(() => {
  //   const config = {
  //     method: 'get',
  //     url: `${BACKEND_URL}/matches?${searchParams ? searchParams : ''}`,
  //     headers: headers,
  //     timeout: 60000,
  //   };
  //   console.log(config.url);
  //   setMatchesData([]);
  //   const getData = async (config, currentStart) => {
  //     try {
  //       const response = await axios(config);
  //       console.log(response);
  //       setLeagueList((prevState) =>
  //         [...prevState, ...response.data.data.map((item) => item.league)].filter((v, i, a) => a.indexOf(v) === i),
  //       );
  //       setMatchesData((prevState) => prevState.concat(response.data.data));
  //       if (
  //         response.data.total > response.data.count &&
  //         response.data.data.length === response.data.count &&
  //         (+currentParams.count > response.data.count || !currentParams.count)
  //       ) {
  //         const newConfig = {
  //           method: 'get',
  //           url: `${BACKEND_URL}/matches?start=${+currentStart + 1000}&count=1000${
  //             currentParams['filter[league]'] ? `&filter[league]=` + currentParams['filter[league]'] : ''
  //           }${currentParams['filter[startFrom]'] ? `&filter[startFrom]=` + currentParams['filter[startFrom]'] : ''}${
  //             currentParams['filter[startTo]'] ? `&filter[startTo]=` + currentParams['filter[startTo]'] : ''
  //           }`,
  //           headers: headers,
  //           timeout: 60000,
  //         };
  //         console.log(newConfig.url);
  //         getData(newConfig, `${+currentStart + response.data.count}`);
  //       }
  //     } catch (err) {
  //       // alert('Error');
  //       if (err.response.status === 401) {
  //         sessionExpiredNotification('error');
  //         navigate('/logout');
  //       }
  //     }
  //   };
  //   const getTableData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios(config);
  //       setMatchesData(response.data);
  //       setIsLoading(false);
  //     } catch (err) {
  //       // alert('Error');
  //       if (err.response.status === 401) {
  //         // alert('Session Timed Out');
  //         sessionExpiredNotification('error');
  //         navigate('/logout');
  //       }
  //     }
  //     setIsLoading(false);
  //   };
  //   getTableData();
  //   // getData(config, currentParams.start ? currentParams.start : '0');
  // }, [searchParams]);

  useEffect(() => {
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/matches?${searchParams ? searchParams : ''}`,
      headers: headers,
      timeout: 60000,
    };

    console.log(config.url);
    const getTableData = async () => {
      setIsLoading(true);
      try {
        const response = await axios(config);
        console.log(config.url);
        console.log(response.data);
        setMatchesData(response.data);
        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          sessionExpiredNotification('error');
          navigate('/logout');
        }
      }
    };
    getTableData();
  }, [searchParams]);

  const resetParams = () => {
    setSearchParams({});
    form.resetFields();
    form.setFieldValue('league', '');
  };

  return (
    <section id="matches">
      <MatchesParametersInput
        currentParams={currentParams}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        leagueList={leagueList}
        resetParams={resetParams}
      />
      <MatchesTable
        data={matchesData}
        leagueList={leagueList}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isLoading={isLoading}
      />
    </section>
  );
};

export default MatchesPages;
