import React from 'react';
import 'antd/dist/antd.css';
import { Table } from 'antd';
import moment from 'moment';

const BetLogTable = ({ data, searchParams, setSearchParams, isLoading }) => {
  const setPagination = (pageNumber, pageSize) => {
    searchParams.set('start', (pageNumber - 1) * pageSize);
    searchParams.set('count', pageSize);
    setSearchParams(searchParams);
  };

  const spreadDataToTable = data.data?.map((item) => {
    return { ...item, ...item.params, ...item.response };
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Status Code',
      dataIndex: 'statusCode',
      key: 'statusCode',
      width: 100,
    },
    {
      title: 'Bet Odd',
      dataIndex: 'betOdd',
      key: 'betOdd',
      width: 100,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: 100,
    },
    {
      title: 'Bet Amount',
      dataIndex: 'betStatus',
      key: 'betStatus',
      width: 100,
    },

    {
      title: 'Action Status',
      dataIndex: 'actionStatus',
      key: 'actionStatus',
      width: 100,
    },
    {
      title: 'Action Message',
      dataIndex: 'actionMessage',
      key: 'actionMessage',
      width: 100,
    },
    {
      title: 'Gold',
      dataIndex: 'gold',
      key: 'gold',
      width: 100,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: 100,
    },
    {
      title: 'Event ID',
      dataIndex: 'eventId',
      key: 'eventId',
      width: 100,
    },
    {
      title: 'Match ID',
      dataIndex: 'matchId',
      key: 'matchId',
      width: 100,
    },
    {
      title: 'Record ID',
      dataIndex: 'recordId',
      key: 'recordId',
      width: 100,
    },
    {
      title: 'Sport Type',
      dataIndex: 'sportType',
      key: 'sportType',
      width: 100,
    },
    {
      title: 'Target Odd',
      dataIndex: 'targetOdd',
      key: 'targetOdd',
      width: 100,

      render: (item) => item.toFixed(3),
    },
    {
      title: 'Target Type',
      dataIndex: 'targetType',
      key: 'targetType',
      width: 100,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,

      render: (number) => moment(number).format('ddd D MMM YY HH:mm:ss'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 100,

      render: (number) => moment(number).format('ddd D MMM YY HH:mm:ss'),
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={spreadDataToTable}
      rowKey="id"
      bordered
      pagination={{
        total: data.total,
        defaultPageSize: 1000,
        pageSizeOptions: [500, 1000, 3000],
        showQuickJumper: true,
        onChange: (pageNumber, pageSize) => {
          setPagination(pageNumber, pageSize);
          console.log(searchParams);
        },
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} entries`,
      }}
      size="middle"
      scroll={{
        y: 600,
      }}
      loading={isLoading}
    />
  );
};

export default BetLogTable;
