// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Table, Collapse } from 'antd';
import { headerConfig } from '../../utils/header';
import axios from 'axios';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const { Panel } = Collapse;

const CalculationTable = ({ searchParams, isLoading }) => {
  const headers = headerConfig();
  const [calculationsData, setCalculationsData] = useState([]);
  useEffect(() => {
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/bets/roi?${searchParams ? searchParams : ''}`,
      headers: headers,
      timeout: 60000,
    };
    const getData = async () => {
      const response = await axios(config);
      console.log(response);
      setCalculationsData([{ ...response.data, id: 0 }]);
    };
    getData();
  }, [searchParams]);

  const columns = [
    {
      title: 'Total Bets',
      dataIndex: 'totalBets',
      key: 'totalBets',
      width: 100,
      align: 'center',
      render: (amount) => amount || '0',
    },
    {
      title: 'Total Amount Risk',
      dataIndex: 'totalAmountRisk',
      key: 'totalAmountRisk',
      width: 100,
      align: 'center',
      render: (amount) => (amount ? `$${amount.toFixed(2)}` : 'N/A'),
    },
    {
      title: 'Sum of Expected Winnings',
      dataIndex: 'sumOfExpectedWinnings',
      key: 'sumOfExpectedWinnings',
      width: 100,
      align: 'center',
      render: (amount) => (amount ? `${amount >= 0 ? '$' : '-$'}${Math.abs(amount).toFixed(2)}` : 'N/A'),
    },
    {
      title: 'Expected ROI',
      dataIndex: 'expectedROI',
      key: 'expectedROI',
      width: 100,
      align: 'center',
      render: (decimal) => {
        return decimal ? (decimal * 100).toFixed(2) + '%' : 'N/A';
      },
    },
    {
      title: 'Sum of Actual Winnings',
      dataIndex: 'sumOfActualWinnings',
      key: 'sumOfActualWinnings',
      width: 100,
      align: 'center',
      render: (amount) => (amount ? `${amount >= 0 ? '$' : '-$'}${Math.abs(amount).toFixed(2)}` : 'N/A'),
    },
    {
      title: 'Actual ROI',
      dataIndex: 'actualROI',
      key: 'actualROI',
      width: 100,
      align: 'center',
      render: (decimal) => {
        return decimal ? (decimal * 100).toFixed(2) + '%' : 'N/A';
      },
    },
    {
      title: 'Total Amount Pending',
      dataIndex: 'totalAmountPending',
      key: 'totalAmountPending',
      width: 100,
      align: 'center',
      render: (amount) => (amount ? `${amount >= 0 ? '$' : '-$'}${Math.abs(amount).toFixed(2)}` : 'N/A'),
    },
  ];

  return <Table columns={columns} dataSource={calculationsData} pagination={false} rowKey="id" loading={isLoading}/>;
};

export default CalculationTable;
