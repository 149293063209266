import React from "react";
import TableBase from "./Base";
import { DateTimeFormat, NumberToDateTime } from "../../utils/global";
import { givenOdds } from "../../utils/bet";
import moment from "moment";

const BetsTable = ({
  scrollHeight,
  actionWidth,
  withAction,
  dataSource,
  ...props
}) => {
  const COLUMNS = [
    {
      title: "Start Time",
      dataIndex: "match",
      render: (match) => {
        return <span>{NumberToDateTime(match?.startTime)}</span>
      },
      sorter: (a, b) => a.startTime - b.startTime,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (createdAt) => {
        return <span>{DateTimeFormat(createdAt)}</span>
      },
      sorter: (a, b) => moment(a.createdAt).format("x") - moment(b.createdAt).format("x"),
    },
    {
      title: "League",
      dataIndex: "match",
      render: (match) => {
        return <span>{match?.league}</span>
      }
    },
    {
      title: "Home",
      dataIndex: "match",
      render: (match) => {
        return <span>{match?.host}</span>
      }
    },
    {
      title: "Away",
      dataIndex: "match",
      render: (match) => {
        return <span>{match?.guest}</span>
      }
    },
    {
      title: "True Odds",
      children: [
        {
          title: 'Home',
          dataIndex: 'homeTrueOdd',
          width: 70,
          align: 'center',
        },
        {
          title: 'Draw',
          dataIndex: 'drawTrueOdd',
          width: 70,
          align: 'center',
        },
        {
          title: 'Away',
          dataIndex: 'awayTrueOdd',
          width: 70,
          align: 'center',
        },
      ],
    },
    {
      title: "Selection",
      dataIndex: "selected",
    },
    {
      title: "Edge",
      dataIndex: "edge",
      render: (value) => {
        return <span>{value} %</span>
      },
      sorter: (a, b) => a.edge - b.edge,
    },
    {
      title: "Winner",
      dataIndex: 'winner',
      render: (winner) => {
        return <span>{winner ? winner : 'Pending'}</span>
      },
    },
    {
      title: "Bookie",
      dataIndex: 'source'
    },
    {
      title: "Given (O)",
      render: (record) => {
        return givenOdds(record);
      }
    },
    {
      title: "Amount",
      dataIndex: 'amount',
      render: (amount) => `$${amount.toFixed(2)}`,
    },
    {
      title: 'Expected Winning',
      dataIndex: 'expectedWinnings',
      width: 100,
      align: 'center',
      render: (value) => {
        if (value != undefined) {
          return value >= 0 ? `$${value.toFixed(2)}` : `-$${Math.abs(value.toFixed(2))}`;
        }
      },
      sorter: (a, b) => a.expectedWinnings - b.expectedWinnings,
    },
    {
      title: 'Bet Result',
      width: 100,
      align: 'center',
      render: (record) => {
        return record?.winners != null ? (record?.winners === record?.selected ? 'Win' : 'Loss') : 'Pending'
      }
    },
    {
      title: 'Net',
      dataIndex: 'net',
      width: 80,
      align: 'center',
      render: (value) => {
        if (value != undefined) {
          return value >= 0 ? `$${value.toFixed(2)}` : `-$${Math.abs(value.toFixed(2))}`;
        }
      },
      sorter: (a, b) => a.net - b.net,
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: "action",
      width: actionWidth || 100,
      fixed: "right",
      render(record) {
        return withAction(record);
      },
    });
  }

  if (scrollHeight) {
    return (
      <TableBase
        dataSource={dataSource}
        columns={COLUMNS}
        scroll={{ x: 991, y: scrollHeight }}
        {...props}
      />
    );
  } else {
    return <TableBase dataSource={dataSource} columns={COLUMNS} scroll={{ x: 991 }} {...props} />;
  }
};

export default BetsTable;
