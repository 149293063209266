import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Select, Form, Checkbox, DatePicker, Button, Tooltip, Menu, Dropdown, Collapse, Segmented } from 'antd';
import axios from 'axios';
import { headerConfig } from '../../utils/header';
import { useNavigate } from 'react-router-dom';
import Input from 'antd/lib/input/Input';
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const BetsParameterInputs = ({
  searchParams,
  setSearchParams,
  currentParams,
  bookieList,
  csvValidData,
  csvAllData,
  fetchValidBets,
  setFetchValidBets,
  betsData,
}) => {
  const [leagueList, setLeagueList] = useState([]);
  const headers = headerConfig();
  const navigate = useNavigate();
  const statusList = [
    'PENDING',
    'ACCEPTED',
    'REJECTED',
    'ACCEPTED_UNKNOWN_ID',
    'WIN',
    'LOSS',
    'CANCELLED',
    'DRAW',
    'REJECTED_ABNORMAL',
    'EXCHANGE_DELETED',
    'EXCHANGE_MATCH_AWAIT',
  ].sort((a, b) => a.localeCompare(b));
  useEffect(() => {
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/leagues?filter[acceptBet]=true&`,
      headers: headers,
      timeout: 60000,
    };
    const getData = async (config, currentStart) => {
      try {
        console.log(config.url);
        const response = await axios(config);
        setLeagueList((prevState) => prevState.concat(response.data.data));
        console.log(response);

        if (
          response.data.total > 1000 &&
          response.data.data.length === 1000 &&
          (+currentParams.count > 1000 || !currentParams.count)
        ) {
          const newConfig = {
            method: 'get',
            url: `${BACKEND_URL}/leagues?filter[acceptBet]=true&start=${+currentStart + 1000}&count=1000`,
            headers: headers,
            timeout: 60000,
          };
          getData(newConfig, `${+currentStart + 1000}`);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          navigate('login', { replace: false });
        }
      }
    };
    getData(config, currentParams.start ? currentParams.start : '0');
  }, []);
  const [form] = Form.useForm();
  const bookieMenuItems = bookieList.map((item, index) => {
    return (
      <Option key={index} value={item}>
        {item}
      </Option>
    );
  });
  const leagueMenuItems = leagueList
    .map((item) => item.name)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort((a, b) => a.localeCompare(b))
    .map((item, index) => {
      return (
        <Option key={index} value={item}>
          {item}
        </Option>
      );
    });
  const statusMenuItems = statusList
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort((a, b) => a.localeCompare(b))
    .map((item, index) => {
      return (
        <Option key={index} value={item}>
          {item}
        </Option>
      );
    });

  const resetParams = () => {
    setSearchParams({});
    form.resetFields();
    form.setFieldValue('matchId', '');
    form.setFieldValue('league', '');
    form.setFieldValue('bookie', '');
    form.setFieldValue('dateRange', ['', '']);
  };
  const quickSelectionDate = (selection) => {
    form.setFieldValue('dateRange', [moment(selection), moment()]);
    searchParams.set('filter[createdAtFrom]', moment(selection).format('DD/MM/YYYY'));
    searchParams.set('filter[createdAtTo]', moment().format('DD/MM/YYYY'));
    searchParams.delete('count');
    setSearchParams(searchParams);
  };

  const quickSelectionDateValues = [
    {
      displayedValue: '1D',
      number: 0,
      period: 'days',
      description: 'Show 1 day data',
    },
    {
      displayedValue: '5D',
      number: 4,
      period: 'days',
      description: 'Show 5 day data',
    },
    {
      displayedValue: '1M',
      number: 1,
      period: 'months',
      description: 'Show 1 month data',
    },
    {
      displayedValue: '1Y',
      number: 1,
      period: 'years',
      description: 'Show 1 year data',
    },
  ];

  const quickSelectionDateTableCells = quickSelectionDateValues.map((item) => {
    return (
      <Tooltip placement="bottom" title={item.description} key={item.displayedValue}>
        <td
          style={{
            fontWeight:
              currentParams['filter[createdAtFrom]'] ===
                moment().subtract(item.number, item.period).format('DD/MM/YYYY') &&
              currentParams['filter[createdAtTo]'] === moment().format('DD/MM/YYYY')
                ? '600'
                : null,
          }}
          onClick={() => quickSelectionDate(moment().subtract(item.number, item.period))}
        >
          {item.displayedValue}
        </td>
      </Tooltip>
    );
  });

  const quickSelectionCount = (selection) => {
    selection ? searchParams.set('count', selection) : searchParams.delete('count');
    searchParams.set('start', '0');
    searchParams.delete('filter[createdAtFrom]');
    searchParams.delete('filter[createdAtTo]');
    form.setFieldValue('dateRange', ['', '']);
    setSearchParams(searchParams);
  };
  const quickSelectionCountValues = [
    { displayedValue: '100', number: '100' },
    { displayedValue: '200', number: '200' },
    { displayedValue: '1k', number: '1000' },
    { displayedValue: '2k', number: '2000' },
    { displayedValue: 'Max', number: '' },
  ];

  const quickSelectionCountTableCells = quickSelectionCountValues.map((item) => {
    return (
      <Tooltip
        placement="bottom"
        title={item.number ? `Show ${item.number} entries` : 'Show all entries'}
        key={item.displayedValue}
      >
        <td
          style={{
            fontWeight:
              item.number === currentParams.count ||
              (currentParams.count === undefined && item.number === '' && !currentParams['filter[createdAtFrom]'])
                ? '600'
                : null,
          }}
          onClick={() => quickSelectionCount(item.number)}
        >
          {item.displayedValue}
        </td>
      </Tooltip>
    );
  });
  const quickSelectionTable = (
    <table>
      <tbody>
        <tr>
          {/* {quickSelectionDateTableCells} */}
          {quickSelectionCountTableCells}
        </tr>
      </tbody>
    </table>
  );

  const quickSelectionItems = [
    {
      displayedValue: '1D',
      number: 0,
      period: 'days',
      description: 'Show 1 day data',
      function: (number) => quickSelectionDate(number),
    },
    {
      displayedValue: '5D',
      number: 4,
      period: 'days',
      description: 'Show 5 day data',
    },
    {
      displayedValue: '1M',
      number: 1,
      period: 'months',
      description: 'Show 1 month data',
    },
    {
      displayedValue: '1Y',
      number: 1,
      period: 'years',
      description: 'Show 1 year data',
    },
  ];

  const quickSelection = (selection) => {};

  //Profit by league formulas

  const leaguesInBets = betsData
    ?.filter((item) => item.winners)
    .map((item) => item?.match.league)
    .filter((v, i, a) => a.indexOf(v) === i);

  const individualLeagueData = (league, array) => {
    return array?.filter((item) => item?.match?.league === league);
  };

  const netByTenDollarBet = (bet) => {
    if (bet.selected === bet.winners) {
      switch (bet.selected) {
        case 'HOME':
          return bet.odd.rateOver * 10 - 10;
        case 'AWAY':
          return bet.odd.rateUnder * 10 - 10;
        case 'DRAW':
          return bet.odd.rateEqual * 10 - 10;
      }
    } else {
      return 0 - bet.amount;
    }
  };

  const totalProfitByLeague = (league, array) => {
    return individualLeagueData(league, array)
      .map((item) => netByTenDollarBet(item))
      .reduce((total, item) => {
        return total + item;
      }, 0);
  };

  const profitByLeagueByWeek = (number) => {
    return betsData.filter((item) => item.match.startTime >= +moment().subtract(number, 'weeks').format('X'));
  };

  const csvByWeek = (league, number) => {
    {
      return {
        ['numberOfBetsPast' + number + 'weeks']: individualLeagueData(league, profitByLeagueByWeek(number))?.length,
        ['totalBetAmountPast' + number + 'weeks']:
          individualLeagueData(league, profitByLeagueByWeek(number))?.length * 10,
        ['profitPast' + number + 'weeks']: totalProfitByLeague(league, profitByLeagueByWeek(number)).toFixed(2),
        ['roiPast' + number + 'weeks']:
          individualLeagueData(league, profitByLeagueByWeek(number))?.length > 0
            ? `${(
                (totalProfitByLeague(league, profitByLeagueByWeek(number)) /
                  (individualLeagueData(league, profitByLeagueByWeek(number)).length * 10)) *
                100
              ).toFixed(2)}%`
            : 'No bets',
      };
    }
  };

  const csvAllWeeks = leaguesInBets?.map((item, index) => {
    return {
      ...{
        key: index + 1,
        league: item,
        numberOfBets: individualLeagueData(item, betsData)?.length,
        totalBetAmount: individualLeagueData(item, betsData)?.length * 10,
        profit: totalProfitByLeague(item, betsData).toFixed(2),
        roi: `${(
          (totalProfitByLeague(item, betsData) / (individualLeagueData(item, betsData).length * 10)) *
          100
        ).toFixed(2)}%`,
        numberOfBetsPast1Week: individualLeagueData(item, profitByLeagueByWeek(1))?.length,
      },
      ...csvByWeek(item, 1),
      ...csvByWeek(item, 2),
      ...csvByWeek(item, 3),
      ...csvByWeek(item, 4),
    };
  });

  console.log(csvAllWeeks);

  const profitByLeagueCsv =
    csvAllWeeks &&
    csvAllWeeks?.map((firstItem) => ({
      ...firstItem,
      // ...csvByWeek(1)?.find((item) => item.key === firstItem.key),
    }));

  const csvMenu = (
    <Menu
      items={[
        // {
        //   key: '1',
        //   label: <CSVLink data={csvValidData}>Valid bets only</CSVLink>,
        // },
        {
          key: '2',
          label: csvAllData && <CSVLink data={csvAllData}>CSV Download</CSVLink>,
        },
        {
          key: '3',
          label: profitByLeagueCsv && <CSVLink data={profitByLeagueCsv}>Profit By Leagues</CSVLink>,
        },
      ]}
    />
  );
  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header="Search Options" key="1">
        <Form form={form} colon={false}>
          <Dropdown overlay={csvMenu} placement="bottomRight">
            <Button>CSV</Button>
          </Dropdown>
          <Form.Item
            label="Match ID"
            name="matchId"
            initialValue={currentParams['filter[matchId]'] ? currentParams['filter[matchId]'] : ''}
            id="betMatchIdParam"
          >
            <Input
              size="medium"
              value={currentParams['filter[matchId]'] ? currentParams['filter[matchId]'] : ''}
              onChange={(e) => {
                console.log(e);
                e.target.value
                  ? searchParams.set('filter[matchId]', e.target.value)
                  : searchParams.delete('filter[matchId]');
                searchParams.delete('start');
                setSearchParams(searchParams);
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            label="League"
            name="league"
            initialValue={currentParams['filter[league]'] ? currentParams['filter[league]'] : ''}
            id="betLeagueParam"
          >
            <Select
              showSearch
              size="medium"
              value={currentParams['filter[league]'] ? currentParams['filter[league]'] : ''}
              onChange={(e) => {
                console.log(e);
                e ? searchParams.set('filter[league]', e) : searchParams.delete('filter[league]');
                searchParams.delete('start');
                setSearchParams(searchParams);
              }}
            >
              <Option value={''}>Any</Option>
              {leagueMenuItems}
            </Select>
          </Form.Item>
          <Form.Item
            className="bookieParam"
            label="Bookie"
            name="bookie"
            initialValue={currentParams['filter[source]'] ? currentParams['filter[source]'] : ''}
          >
            <Select
              showSearch
              size="medium"
              value={currentParams['filter[source]'] ? currentParams['filter[source]'] : ''}
              onChange={(e) => {
                console.log(e);
                e ? searchParams.set('filter[source]', e) : searchParams.delete('filter[source]');
                searchParams.delete('start');
                setSearchParams(searchParams);
              }}
            >
              <Option value={''}>Any</Option>
              {bookieMenuItems}
            </Select>
          </Form.Item>
          <Form.Item
            className="statusParam"
            label="Bet Status"
            name="betStatus"
            initialValue={currentParams['filter[status]'] ? currentParams['filter[status]'] : ''}
          >
            <Select
              showSearch
              size="medium"
              value={currentParams['filter[status]'] ? currentParams['filter[status]'] : ''}
              onChange={(e) => {
                console.log(e);
                e ? searchParams.set('filter[status]', e) : searchParams.delete('filter[status]');
                searchParams.delete('start');
                setSearchParams(searchParams);
              }}
            >
              <Option value={''}>Any</Option>
              {statusMenuItems}
            </Select>
          </Form.Item>
          <Form.Item label="Bet Creation Date" name="dateRange" className="createdAtParam">
            <RangePicker
              format={'ddd DD/MM/YYYY'}
              onChange={(newValue) => {
                console.log(newValue);
                searchParams.set('filter[createdAtFrom]', newValue[0].format('DD/MM/YYYY'));
                searchParams.set('filter[createdAtTo]', newValue[1].format('DD/MM/YYYY'));
                searchParams.delete('start');
                setSearchParams(searchParams);
                console.log(currentParams['filter[createdAtFrom]']);
              }}
            />
          </Form.Item>
          {quickSelectionTable}
          <Button variant="contained" onClick={() => resetParams()}>
            Reset
          </Button>
          <Checkbox checked={fetchValidBets} onChange={(e) => setFetchValidBets(e.target.checked)}>
            Valid Bets Only?
          </Checkbox>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default BetsParameterInputs;
