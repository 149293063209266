import React from "react";
import { connect } from "react-redux";
import { doLogout } from "../stores/slice";
import { doGetAuthenticatorQRCode } from "../stores/thunk";

export default (Component) => {
  class Container extends React.Component {
    render() {
      return <Component {...this.props} />
    }
  }
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      isFetching: state.auth.isFetching
    };
  }
  return connect(mapStateToProps, { doLogout, doGetAuthenticatorQRCode })(Container);
};
