import { Button, Form, Input } from 'antd';
import React from 'react';

const LoginForm = ({ handleSubmit }) => {
  return (
    <div className="SignInForm">
      <Form hideRequiredMark colon={false} onFinish={handleSubmit} scrollToFirstError>
        <h1>Sign In</h1>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Button type="primary" htmlType="Submit">
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default LoginForm;