import React from 'react';
import withUnAuth from './withUnAuth';
import VerifyOtpForm from '../Components/Forms/VerifyOtp';
import { VerifyOtpContainer } from '../modules/auth/containers';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../constants';

const VerifyOtpPage = ({ auth, verifyToken, doVerifyOtp }) => {
  console.log(verifyToken);
  const tokenCookie = Cookies.get(TOKEN_NAME);
  console.log(tokenCookie);
  const verifyTokenConverted = JSON.parse(tokenCookie).verifyToken;

  const handleSubmit = (values) => {
    const newValues = values;
    newValues.verifyToken = auth.verifyToken || verifyTokenConverted;
    doVerifyOtp(newValues);
  };

  return (
    <div className="signin-container">
      <div className="content-page">
        <div className="form-container">
          <VerifyOtpForm onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default withUnAuth(VerifyOtpContainer(VerifyOtpPage));
