import React, { useEffect, useState } from 'react';
import LeaguesTable from '../Components/Leagues/LeaguesTable';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Select, Form, Radio, DatePicker, Button, Tooltip, Row, Col, notification, Modal } from 'antd';
import { headerConfig } from '../utils/header';
import LeaguesParameterInputs from '../Components/Leagues/LeaguesParameterInputs';
import '../Components/Leagues/Leagues.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const LeaguesPage = () => {
  const navigate = useNavigate();
  const headers = headerConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [leaguesData, setLeaguesData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const currentParams = Object.fromEntries([...searchParams]);
  const [form] = Form.useForm();

  const sessionExpiredNotification = (type) => {
    notification[type]({
      message: 'Session Expired',
      description: 'Your session has expired, please login again.',
    });
  };

  // useEffect(() => {
  //   const config = {
  //     method: 'get',
  //     url: `${BACKEND_URL}/leagues?${searchParams ? searchParams : ''}`,
  //     headers: headers,
  //     timeout: 60000,
  //   };
  //   console.log(config.url);
  //   setLeaguesData([]);
  //   const getData = async (config, currentStart) => {
  //     try {
  //       const response = await axios(config);
  //       console.log(response);
  //       setLeaguesData((prevState) =>
  //         [...prevState, ...response.data.data].map((item) => {
  //           return { ...item, key: item.id };
  //         }),
  //       );
  //       if (
  //         response.data.total > response.data.count &&
  //         response.data.data.length === response.data.count &&
  //         (+currentParams.count > response.data.count || !currentParams.count)
  //       ) {
  //         const newConfig = {
  //           method: 'get',
  //           url: `${BACKEND_URL}/leagues?start=${+currentStart + 1000}&count=1000${
  //             currentParams['filter[name]'] ? `&filter[name]=` + currentParams['filter[name]'] : ''
  //           }${currentParams['filter[acceptBet]'] ? `&filter[acceptBet]=` + currentParams['filter[acceptBet]'] : ''}`,
  //           headers: headers,
  //           timeout: 60000,
  //         };
  //         getData(newConfig, `${+currentStart + response.data.count}`);
  //       }
  //     } catch (err) {
  //       // alert('Error');
  //       if (err?.response?.status === 401) {
  //         sessionExpiredNotification('error');
  //         navigate('/logout');
  //       }
  //     }
  //   };
  //   const getTableData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios(config);
  //       setLeaguesData(response.data);
  //       setIsLoading(false);
  //     } catch (err) {
  //       // alert('Error');
  //       if (err.response.status === 401) {
  //         // alert('Session Timed Out');
  //         sessionExpiredNotification('error');
  //         navigate('/logout');
  //       }
  //     }
  //     setIsLoading(false);
  //   };
  //   // getData(config, currentParams.start ? currentParams.start : '0');
  //   getTableData();
  // }, [searchParams]);

  useEffect(() => {
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/leagues?${searchParams ? searchParams : ''}`,
      headers: headers,
      timeout: 60000,
    };

    console.log(config.url);
    const getTableData = async () => {
      setIsLoading(true);
      try {
        const response = await axios(config);
        console.log(config.url);
        console.log(response.data);
        setLeaguesData(response.data);
        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          sessionExpiredNotification('error');
          navigate('/logout');
        }
      }
    };
    getTableData();
  }, [searchParams]);

  const resetParams = () => {
    setSearchParams({});
    form.resetFields();
    form.setFieldValue('league', '');
  };

  return (
    <section id="leagues">
      <LeaguesParameterInputs
        currentParams={currentParams}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        resetParams={resetParams}
      />
      <LeaguesTable
        data={leaguesData}
        setLeaguesData={setLeaguesData}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isLoading={isLoading}
      />
    </section>
  );
};

export default LeaguesPage;
