import { Select, Form, Radio, Button, Collapse } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { headerConfig } from '../../utils/header';
const { Option } = Select;
const { Panel } = Collapse;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const LeaguesParameterInputs = ({ currentParams, searchParams, setSearchParams }) => {
  const headers = headerConfig();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [leagueList, setLeagueList] = useState([]);

  useEffect(() => {
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/leagues`,
      headers: headers,
      timeout: 60000,
    };
    const getData = async (config, currentStart) => {
      try {
        console.log(config.url);
        const response = await axios(config);
        setLeagueList((prevState) => prevState.concat(response.data.data));
        console.log(response);

        if (
          response.data.total > response.data.count &&
          response.data.data.length === response.data.count &&
          (+currentParams.count > response.data.count || !currentParams.count)
        ) {
          const newConfig = {
            method: 'get',
            url: `${BACKEND_URL}/leagues?start=${+currentStart + response.data.count}&count=${response.data.count}`,
            headers: headers,
            timeout: 60000,
          };
          getData(newConfig, `${+currentStart + response.data.count}`);
        }
      } catch (err) {
        console.log(err);
        if ((err.response.status = 401)) {
          navigate('login', { replace: false });
        }
      }
    };
    getData(config, currentParams.start ? currentParams.start : '0');
  }, []);

  const resetParams = () => {
    setSearchParams({});
    form.resetFields();
    form.setFieldValue('name', '');
  };
  const leagueMenuItems = leagueList
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort()
    .map((item, index) => {
      return (
        <Option key={index} value={item.name}>
          {item.name}
        </Option>
      );
    });
  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header="Search Options" key="1">
        <Form form={form} colon={false}>
          <Form.Item label="Name" name="name" className="name" initialValue={''}>
            <Select
              showSearch
              size="medium"
              value={currentParams['filter[name]'] ? currentParams['filter[name]'] : ''}
              onChange={(e) => {
                console.log(e);
                e ? searchParams.set('filter[name]', e) : searchParams.delete('filter[name]');
                searchParams.delete('start');
                setSearchParams(searchParams);
              }}
            >
              <Option value={''}>Any</Option>
              {leagueMenuItems}
            </Select>
          </Form.Item>
          <Form.Item
            label="Accept Bet"
            name="acceptBet"
            className="acceptBet"
            initialValue={currentParams['filter[acceptBet]']}
          >
            <Radio.Group
              value={currentParams['filter[acceptBet]']}
              onChange={(e) => {
                console.log(e.target.value);
                e.target.value
                  ? searchParams.set('filter[acceptBet]', e.target.value)
                  : searchParams.delete('filter[acceptBet]');
                searchParams.delete('start');
                setSearchParams(searchParams);
              }}
            >
              <Radio.Button value={undefined}>Any</Radio.Button>
              <Radio.Button value="true">Yes</Radio.Button>
              <Radio.Button value="false">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Button variant="contained" onClick={() => resetParams()}>
            Reset
          </Button>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default LeaguesParameterInputs;
