import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { doSignIn } from "../stores/thunk";

export default (Component) => {
  class Container extends React.Component {
    render() {
      const { auth } = this.props;
      const requireOTP = auth.requireOTP;
      const require2FA = requireOTP === false ? true : false;
      // if (auth && !auth.authenitcated && !auth.verified) {
      //   return <Navigate to={'/login'} />
      // }
      console.log(auth)
      if (requireOTP) {
        return <Navigate to={'/verify-otp'} />
      } else if (require2FA) {
        return <Navigate to={'/scan-authenticator-code'} />
      } else {
        return <Component { ...this.props} />
      }
    }
  }
  function mapStateToProps(state) {
    return {
      auth: state.auth,
    };
  }
  return connect(mapStateToProps, { doSignIn })(
    Container
  );
};
