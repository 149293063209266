import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { TOKEN_NAME } from '../../../constants';
import { doSignIn, doVerifyOtp, doGetAuthenticatorQRCode } from './thunk';

const tokenCookie = Cookies.get(TOKEN_NAME);
const tokenParsed = tokenCookie ? JSON.parse(tokenCookie) : undefined;

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isFetching: false,
    authenticated: !!Cookies.get(TOKEN_NAME),
    user: tokenParsed ? tokenParsed.user : undefined,
    requireOTP: undefined,
    verifyToken: tokenParsed ? tokenParsed.verifyToken : null,
    authToken: tokenParsed ? tokenParsed.authToken : null,
    refreshToken: null,
    qrCode: undefined,
    verified: tokenParsed ? !!tokenParsed.user : false,
  },
  reducers: {
    doLogout: (state) => {
      console.log("adasad")
      Cookies.remove(TOKEN_NAME);
      state.authenticated = false;
      state.user = undefined;
      state.requireOTP = undefined;
      console.log('state logout ', state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doSignIn.fulfilled, (state, action) => {
      const { data } = action.payload;
      const requireOTP = data?.requireOTP;
      state.requireOTP = requireOTP;
      state.user = undefined;
      state.authenticated = false;
      let authInfo = {};
      if (data.verifyToken) {
        state.verifyToken = data.verifyToken;
        authInfo = { verifyToken: data.verifyToken };
      } else {
        state.authToken = data.authToken;
        authInfo = { authToken: data.authToken };
      }
      Cookies.set(TOKEN_NAME, JSON.stringify(authInfo));
    });
    builder.addCase(doVerifyOtp.fulfilled, (state, action) => {
      const { data } = action.payload;
      const userInfo = {
        name: data.name,
        email: data.email,
      };
      const authInfo = {
        user: userInfo,
        authToken: data.authToken,
        refreshToken: data.refreshToken,
        verifyToken: null,
      };
      const authInfoJson = JSON.stringify(authInfo);
      Cookies.set(TOKEN_NAME, authInfoJson);
      state.user = userInfo;
      state.authToken = data.authToken;
      state.refreshToken = data.refreshToken;
      state.authenticated = true;
      state.verifyToken = null;
      state.verified = true;
    });
    builder.addCase(doGetAuthenticatorQRCode.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetAuthenticatorQRCode.fulfilled, (state, action) => {
      state.isFetching = false;
      const { data } = action.payload;
      state.qrCode = data;
    });
    builder.addCase(doGetAuthenticatorQRCode.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

const { actions, reducer: authReducer } = authSlice;
export const { doLogout } = actions;
export default authReducer;
