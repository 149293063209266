import './SiderContent.scss';
import React, { useState } from 'react';
import { Button, Layout } from 'antd';

import MainMenu from '../Menu/Main';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const { Sider } = Layout;

const SiderContent = () => {
  const [collapsed, setCollapsed] = useState(true);

  const onTriggerClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Sider
        breakpoint="lg"
        collapsible
        collapsedWidth={0}
        trigger={null}
        collapsed={collapsed}
        // onBreakpoint={(broken) => {
        //   if (broken) setCollapsed(true);
        //   else setCollapsed(false);
        // }}
        className="sider side-menu-component"
      >
        <MainMenu />
      </Sider>
      <div className={`mobile-toggle-menu ${collapsed ? 'hide' : 'open'}`}>
        <Button className="trigger" onClick={onTriggerClick}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
    </>
  );
};
export default SiderContent;
