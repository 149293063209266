import withUnAuth from "./withUnAuth";
import AuthContainer from "../modules/auth/containers/Auth";
import './Login.scss';
import LoginForm from '../Components/Forms/Login';

const LoginPage = ({doSignIn}) => {
  const handleSubmit = (values) => {
    doSignIn(values);
  }

  return (
    <div className="signin-container">
      <div className="content-page">
        <div className="form-container">
          <LoginForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default withUnAuth(AuthContainer(LoginPage));
