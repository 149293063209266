import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logout from '../../modules/auth/containers/Logout';
import UserBalance from '../../modules/auth/containers/UserBalance';
import { doGetUserBalance } from '../../modules/auth/stores/thunk';
import { headerConfig } from '../../utils/header';
import './Header.css';
const { Header } = Layout;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const headers = headerConfig();

const HeaderMain = ({ user, userBalance }) => {
  const state = useSelector((state) => state);
  console.log(state)
  const menuItems = [
    {
      key: Logout,
      label: (
        <Link to="/logout" className="logout">
          <Button type="text">Log out</Button>
        </Link>
      ),
    },
  ];

  return (
    <Header className="site-header">
      {userBalance && (
        <div className="user-balance">
          <p>{`Credit: ${userBalance.currency} $${userBalance?.credit}`}</p>
          <p>{`P/L: ${userBalance.currency} $${userBalance?.pl}`}</p>
          <p>{`Outstanding: ${userBalance.currency} $${userBalance?.outstanding}`}</p>
          <p>{`Balance: ${userBalance.currency} $${userBalance?.credit + userBalance?.outstanding}`}</p>
        </div>
      )}

      <div className="logo"></div>
      <div className="account-info">
        <div className="avatar">
          <img
            src={`https://ui-avatars.com/api/?length=1&name=${user?.name}&size=32&font-size=0.4&bold=true&background=5f5f5f&color=FFFFFF&rounded=true`}
            alt=""
          />
        </div>
        <div className="info">
          <div className="fullname">{`${user?.name}`}</div>
          <Dropdown overlayClassName="header-info-dropdown" overlay={<Menu items={menuItems} />}>
            <div className="email">
              {user?.email} <DownOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderMain;
