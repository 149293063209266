import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { doGetUserBalance } from './thunk';

const userBalancesAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

const userBalanceSlice = createSlice({
  name: 'userBalance',
  initialState: {
    isFetching: false,
    metadata: {},
    entities: [],
    ids: [],
    userBalance: undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetUserBalance.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetUserBalance.fulfilled, (state, action) => {
      console.log('state ', state);

      const userBalance = action.payload;
      state.isFetching = false;
      state.userBalance = userBalance;
    });
    builder.addCase(doGetUserBalance.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const {
  selectAll: selectAllUserBalances,
  selectById: selectUserBalanceById,
  selectIds: selectUserBalanceIds,
} = userBalancesAdapter.getSelectors((state) => state.userBalance);

const { reducer: userBalanceReducer } = userBalanceSlice;
export default userBalanceReducer;
