import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable, usePagination } from 'react-table';
import { Link } from 'react-router-dom';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const OddsTable = () => {
  const [matchData, setMatchData] = useState([false]);
  const [tableData, setTableData] = useState([]);
  const [filterStatusParam, setFilterStatusParam] = useState('');
  const [filterMatchIdParam, setFilterMatchIdParam] = useState('');
  const [filterLbTypeParam, setFilterLbTypeParam] = useState('');
  const [filterSourceParam, setFilterSourceParam] = useState('');
  const [filterTypeParam, setFilterTypeParam] = useState('');
  const [startParam, setStartParam] = useState('0');
  const [countParam, setCountParam] = useState('50');

  useEffect(() => {
    const parseDate = (inputDate) => {
      if (inputDate === '') return false;
      const splitDate = inputDate.split('-');
      const parsedDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
      console.log(parsedDate);
      return parsedDate;
    };
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/odds?${startParam ? 'start=' + startParam + '&' : ''}${
        countParam ? 'count=' + countParam + '&' : ''
      }${filterStatusParam ? 'filter[status]=done&' : ''}${
        filterMatchIdParam ? 'filter[matchId]=' + filterMatchIdParam + '&' : ''
      }${filterLbTypeParam ? 'filter[lbType]=' + filterLbTypeParam + '&' : ''}${
        filterSourceParam ? 'filter[source]=' + filterSourceParam + '&' : ''
      }${filterTypeParam ? 'filter[type]=' + filterTypeParam + '&' : ''}
      `,
      headers: {},
    };
    console.log(config.url);
    const getData = async () => {
      try {
        const response = await axios(config);
        console.log(response);
        setMatchData(response.data.data);
      } catch {
        // alert('Error');
      }
    };
    getData();
    return () => {};
  }, [filterStatusParam, filterMatchIdParam, filterLbTypeParam, filterSourceParam, filterTypeParam]);

  useEffect(() => {
    setTableData(
      matchData.map((item) => {
        return {
          col1: item.source,
          col2: item.type,
          col3: item.lbType,
          col4: item.timeType,
          col5: <Link to={`/${item.matchId}`}>{item.matchId}</Link>,
        };
      }),
    );
    return () => {};
  }, [matchData]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Source',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Type',
        accessor: 'col2',
      },
      {
        Header: 'Lb Type',
        accessor: 'col3',
      },
      {
        Header: 'Time Type',
        accessor: 'col4',
      },
      {
        Header: 'Match ID',
        accessor: 'col5',
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    usePagination,
  );

  return (
    <div className="container">
      <label htmlFor="filterStatus">
        Done: ?
        <input
          type="checkbox"
          id="filterStatus"
          name="filterStatus"
          value={filterStatusParam}
          onChange={() => {
            setFilterStatusParam((prevState) => !prevState);
          }}
        />
      </label>{' '}
      <label htmlFor="filterMatchId">
        Match ID:
        <input
          type="number"
          id="filterMatchId"
          name="filterMatchId"
          value={filterMatchIdParam}
          onChange={(e) => {
            setFilterMatchIdParam(e.target.value);
          }}
        />
      </label>{' '}
      <label htmlFor="filterLbType">
        Lb Type:
        <input
          type="text"
          id="filterLbType"
          name="filterLbType"
          value={filterLbTypeParam}
          onChange={(e) => {
            setFilterLbTypeParam(e.target.value);
          }}
        />
      </label>{' '}
      <label htmlFor="filterSource">
        Source:
        <input
          type="text"
          id="filterSource"
          name="filterSource"
          value={filterSourceParam}
          onChange={(e) => {
            setFilterSourceParam(e.target.value);
          }}
        />
      </label>{' '}
      <label htmlFor="filterType">
        Type:
        <input
          type="text"
          id="filterType"
          name="filterType"
          value={filterTypeParam}
          onChange={(e) => {
            setFilterTypeParam(e.target.value);
          }}
        />
      </label>
      <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    background: 'aliceblue',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions?.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default OddsTable;
