import { Spin } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { PAGE_SIZE } from '../../../constants';
import ListConstainer from '../../../modules/bet/containers/List';
import withPage from '../../../Pages/withPage';
import { calculateEdge, calculateWinnings } from '../../../utils/bet';
import BetsFilter from '../../Forms/Bet/Filter';
import BetsTable from '../../Table/Bets';

const BetsPage2 = ({
  query,
  pushTo,
  bets,
  isFetching,
  metadata,
  leagues,
  isLeagueFetching,
  doGetAllBets,
  doGetAllLeagues,
}) => {
  const currentPage = metadata?.start && Number(query?.start) !== 0 ? Math.round((Number(query?.start) - 1) / PAGE_SIZE + 1) : 1;
  const pageOffset = currentPage ? currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE : 0;
  const pagination = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: metadata.total,
    showTotal: (total) => `${total} Items`,
  };

  const [betsData, setBetsData] = useState();

  const handleFilter = (params, updatePath) => {
    const currentPage = params.currentPage;
    delete params.currentPage;
    const offset = currentPage ? currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE : 0;
    params.start = offset;
    const newParams = {start: Number(offset), count: PAGE_SIZE, ...params};

    if (updatePath) {
      pushTo({ query: newParams });
    }
    doGetAllBets(newParams);
  };

  const handleTableChange = (params) => {
    const newParams = { ...query, ...params };
    handleFilter(newParams, true);
  };

  const handleResetFilter = () => {
    doGetAllBets({start: pageOffset, count: PAGE_SIZE, ...query});
  }

  useEffect(() => {
    doGetAllBets({start: pageOffset, count: PAGE_SIZE, ...query});
    // doGetAllLeagues({filter: { acceptBet: true }});
  }, []);

  useEffect(() => {
    if (bets) {
      const newBets = bets.map(bet => {
        return {
          ...bet,
          edge: +(calculateEdge(bet) * 100).toFixed(2),
          expectedWinnings: +(calculateEdge(bet) * bet?.amount).toFixed(2),
          net: bet?.selected === bet?.winners ? +calculateWinnings(bet).toFixed(2) : -bet?.amount,
        }
      });
      setBetsData(newBets);
    }
  }, [bets]);

  return (
    <Spin spinning={isFetching}>
      <div className='page-container'>
        <div className='page-content'>
          <BetsFilter query={query} leagues={leagues} isLeagueFetching={isLeagueFetching} handleFilter={handleFilter} handleResetFilter={handleResetFilter} />
          <BetsTable
            rowKey="id"
            query={query}
            loading={isFetching}
            pagination={pagination}
            dataSource={betsData}
            onChange2={handleTableChange}
            scrollHeight="calc(100vh - 285px)"
            actionWidth={50}
            bordered
          />
        </div>  
      </div>
    </Spin>
  );
}

export default withPage(ListConstainer(BetsPage2));