import React, { Component } from "react";
import { Route } from "react-router-dom";
import { MainLayout } from "../../../Components/Layout";

class Container extends Component {
  render() {
    const { component: Comp, ...props } = this.props;
    return (
      <Route
        {...props}
        render={(p) =>
          Comp && (
            <MainLayout>
              <Comp {...p} />
            </MainLayout>
          )
        }
      />
    );
  }
}

export default Container;
