import {
  Select,
  Form,
  Radio,
  DatePicker,
  Button,
  Tooltip,
  Row,
  Col,
  Menu,
  Dropdown,
  Collapse,
  Segmented,
  Input,
} from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { headerConfig } from '../../utils/header';
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const MatchesParametersInput = ({ currentParams, searchParams, setSearchParams }) => {
  const headers = headerConfig();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [leagueList, setLeagueList] = useState([]);

  useEffect(() => {
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/leagues?filter[acceptBet]=true&`,
      headers: headers,
      timeout: 60000,
    };
    const getData = async (config, currentStart) => {
      try {
        console.log(config.url);
        const response = await axios(config);
        setLeagueList((prevState) => prevState.concat(response.data.data));
        console.log(response);
        if (
          response.data.total > 1000 &&
          response.data.data.length === 1000 &&
          (+currentParams.count > 1000 || !currentParams.count)
        ) {
          const newConfig = {
            method: 'get',
            url: `${BACKEND_URL}/leagues?filter[acceptBet]=true&start=${+currentStart + 1000}&count=1000`,
            headers: headers,
            timeout: 60000,
          };
          // console.log(newConfig.url);
          getData(newConfig, `${+currentStart + 1000}`);
        }
      } catch (err) {
        console.log(err);
        if ((err.response.status = 401)) {
          navigate('login', { replace: false });
        }
      }
    };
    getData(config, currentParams.start ? currentParams.start : '0');
    return () => {};
  }, []);
  const resetParams = () => {
    setSearchParams({});
    form.resetFields();
    form.setFieldValue('uuid', '');
    form.setFieldValue('league', '');
    form.setFieldValue('dateRange', ['', '']);
  };
  const leagueMenuItems = leagueList
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort()
    .map((item, index) => {
      return (
        <Option key={index} value={item.name}>
          {item.name}
        </Option>
      );
    });
  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header="Search Options" key="1">
        <Form form={form} colon={false}>
          <Form.Item
            label="Match UUID"
            name="uuid"
            className="matchUuid"
            initialValue={currentParams['filter[uuid]'] ? currentParams['filter[uuid]'] : ''}
            id="betMatchIdParam"
          >
            <Input
              size="medium"
              value={currentParams['filter[uuid]'] ? currentParams['filter[uuid]'] : ''}
              onChange={(e) => {
                console.log(e);
                e ? searchParams.set('filter[uuid]', e.target.value) : searchParams.delete('filter[uuid]');
                searchParams.delete('start');
                setSearchParams(searchParams);
              }}
            ></Input>
          </Form.Item>
          <Form.Item label="League" name="league" className="league" initialValue={''}>
            <Select
              showSearch
              size="medium"
              value={currentParams['filter[league]'] ? currentParams['filter[league]'] : ''}
              onChange={(e) => {
                console.log(e);
                e ? searchParams.set('filter[league]', e) : searchParams.delete('filter[league]');
                searchParams.delete('start');
                setSearchParams(searchParams);
              }}
            >
              <Option value={''}>Any</Option>
              {leagueMenuItems}
            </Select>
          </Form.Item>
          <Form.Item
            label="Match Status"
            name="matchStatus"
            className="matchStatus"
            initialValue={currentParams['filter[status]']}
          >
            <Radio.Group
              value={currentParams['filter[status]']}
              onChange={(e) => {
                console.log(e.target.value);
                e.target.value
                  ? searchParams.set('filter[status]', e.target.value)
                  : searchParams.delete('filter[status]');
                searchParams.delete('start');
                setSearchParams(searchParams);
              }}
            >
              <Radio.Button value={undefined}>Any</Radio.Button>
              <Radio.Button value="pending">Pending</Radio.Button>
              <Radio.Button value="done">Done</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Start Time" name="startTime" className="startTime">
            <RangePicker
              format={'ddd DD/MM/YYYY'}
              onChange={(newValue) => {
                console.log(newValue);
                searchParams.set('filter[startFrom]', newValue[0].format('DD/MM/YYYY'));
                searchParams.set('filter[startTo]', newValue[1].format('DD/MM/YYYY'));
                searchParams.delete('start');
                setSearchParams(searchParams);
                console.log(currentParams['filter[startFrom]']);
              }}
            />
          </Form.Item>
          <Button variant="contained" onClick={() => resetParams()}>
            Reset
          </Button>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default MatchesParametersInput;
