import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { headerConfig } from '../utils/header';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const BetsCalculationPage = () => {
  const headers = headerConfig();
  const [betsData, setBetsData] = useState([]);
  const [matchesData, setMatchesData] = useState([]);
  const [matchIdsArray, setMatchIdsArray] = useState([]);
  useEffect(() => {
    const config = {
      method: 'get',
      url: `${BACKEND_URL}/bets/?count=100&start=1`,
      headers: headers,
      timeout: 60000
    };
    const getBetsData = async () => {
      try {
        const response = await axios(config);
        console.log(response);
        setBetsData(response.data.data);
      } catch {
        // alert('Error');
      }
    };
    getBetsData();
  }, []);
  useEffect(() => {
    const allBetIds = betsData.map((item) => item.matchId);
    const sortedBetIds = allBetIds.sort();
    const uniqueBetIds = sortedBetIds.filter((v, i, a) => a.indexOf(v) === i);
    setMatchIdsArray(uniqueBetIds);
  }, [betsData]);
  useEffect(() => {
    matchIdsArray.forEach((element) => {
      const config = {
        method: 'get',
        url: `${BACKEND_URL}/matches/${element.toString()}`,
        headers: headers,
      };
      const getIndividualMatchData = async () => {
        try {
          const response = await axios(config);
          console.log(response);
          setMatchesData((prevState) => [...prevState, response.data.events[1]]);
        } catch {
          // alert('Error');
        }
      };
      getIndividualMatchData();
    });
  }, [matchIdsArray]);
  const winBet = (hostScore, awayScore, selection) => {};
  return <div>Hi</div>;
};

export default BetsCalculationPage;
