import React, { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import PrimaryButton from '../Buttons/PrimaryButton';

const VerifyOtpForm = ({ onSubmit }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    const autoFocus = () => {
      inputRef.current.focus({
        cursor: 'start',
      });
    };
    autoFocus();
  }, []);

  return (
    <div className="SignInForm">
      <Form scrollToFirstError layout="vertical" hideRequiredMark colon={false} onFinish={onSubmit}>
        <div className="FormHeader">
          <div className="left">Verify OTP</div>
        </div>
        <div className="FormContent">
          <Form.Item name="otpCode" rules={[{ required: true, message: 'Required' }]}>
            <Input size="large" placeholder="OTP" ref={inputRef} />
          </Form.Item>
        </div>
        <div className="FormFooter">
          <PrimaryButton htmlType="submit" label="Submit" style={{ width: '100%' }} />
        </div>
      </Form>
    </div>
  );
};

export default VerifyOtpForm;
